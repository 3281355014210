// Import airtable from 'airtable';
import Airtable from 'airtable';
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
});

const rocket_airtable = Airtable.base('appKmduywngjp4CQh');
export const challenge_airtable = Airtable.base('appJmvN5BaVRo26NH');
export const teams_table = challenge_airtable('teams');
export const students_table = challenge_airtable('students');
export const catapult_scores_table = challenge_airtable('catapult_scores');
export const bridges_scores_table = challenge_airtable('bridges_teams');
export const special_awards_table = challenge_airtable('special_awards');
export const events_table = challenge_airtable('All Upcoming Events - Master');

export default rocket_airtable;

export const scoring_table = rocket_airtable('scoring');
export const event_table = rocket_airtable('event');
export const main_event_table = challenge_airtable(
  'All Upcoming Events - Master'
);
