// Importing libraries and dependencies
import React, { useState, useEffect } from 'react';
import API from 'configs/api';
import { useParams } from 'react-router-dom';

// Importing layouts and components
import ConfirmMailContent from 'layouts/authentication/ConfirmMailContent';
import AuthCardLayout from 'layouts/AuthCardLayout';
import TokenValidationSpinner from 'layouts/authentication/tokenValidation/TokenValidationSpinner';
import TokenValidationErrorContent from 'layouts/authentication/tokenValidation/TokenValidationErrorContent';

const ConfirmMail = () => {
  let { token } = useParams();
  const [tokenState, setTokenState] = useState({
    validating: true,
    status: undefined
  });

  // Validate token with backend server
  useEffect(async () => {
    try {
      const server_data = await API.put(`/auth/verify/${token}`);
      setTokenState(prevState => {
        return { ...prevState, validating: false, status: server_data.status };
      });
    } catch (error) {
      setTokenState(prevState => {
        return {
          ...prevState,
          validating: false,
          status: error.response.status
        };
      });
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
      } else {
        console.error(error);
      }
    }
  }, []);

  return (
    <AuthCardLayout>
      <div className="text-center">
        {tokenState.validating && <TokenValidationSpinner />}
        {(tokenState.status === 200 || tokenState.status === 201) && (
          <ConfirmMailContent titleTag="h3" />
        )}
        {tokenState.status === 401 && (
          <TokenValidationErrorContent
            titleTag="h3"
            titleText="Invalid Link"
            status={tokenState.status}
          />
        )}
        {tokenState.status === 403 && (
          <TokenValidationErrorContent
            titleTag="h3"
            titleText="Expired Link"
            status={tokenState.status}
          />
        )}
        {tokenState.status === 500 && (
          <TokenValidationErrorContent
            titleTag="h3"
            titleText="Server Error"
            status={tokenState.status}
          />
        )}
      </div>
    </AuthCardLayout>
  );
};

export default ConfirmMail;
