import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { project_categories } from 'data/sciencefair/projectcategories';
import { Controller } from 'react-hook-form';
// import cloudUpload from 'assets/img/icons/cloud-upload.svg';
// import { getSize } from 'helpers/utils';
// import { useDropzone } from 'react-dropzone';
// import Flex from 'components/common/Flex';

const EventRegProjInfo = ({
  register,
  errors,
  control
  // trigger,
  // files,
  // setFiles
}) => {
  // Loading project categories
  const project_categories_options = project_categories.map(
    project_category => {
      return { value: project_category, label: project_category };
    }
  );

  // // Initializing File Dropzone
  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: 'image/*,.doc,.pdf,.docx,.txt',
  //   onDrop: acceptedFiles => setFiles(acceptedFiles)
  // });

  // const removeFileHandler = path => {
  //   setFiles(files.filter(file => file.path !== path));
  // };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Project Information</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-3 gy-3">
          <Form.Group as={Row} className="my-2 mt-3" controlId="project_title">
            <Form.Label column sm="3">
              Project Title
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="project_title"
                placeholder="Project Title"
                isInvalid={errors.project_title}
                {...register('project_title', {
                  required: 'Please enter the project title.'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.project_title?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="project_category">
            <Form.Label column sm="3">
              Project Category
            </Form.Label>
            <Col sm="9">
              <Controller
                control={control}
                name="project_category"
                rules={{ required: 'This field is required.' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={project_categories_options}
                    placeholder="Select project category..."
                    isClearable
                    closeMenuOnSelect
                    // classNamePrefix="form-control"
                  />
                )}
              />
              {errors.project_category && (
                <div className="invalid-feedback d-block">
                  {errors?.project_category?.message}
                </div>
              )}
            </Col>
          </Form.Group>
          {/* 
          <Form.Group as={Row} className="my-2" controlId="project_abstract">
            <Form.Label column sm="3">
              Project Abstract
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                rows={5}
                name="project_abstract"
                placeholder={
                  teamCategory === 'Senior Division'
                    ? 'Project Abstract (required)'
                    : 'Project Abstract (optional)'
                }
                isInvalid={errors.project_abstract}
                {...register('project_abstract', {
                  required:
                    teamCategory === 'Senior Division'
                      ? 'The project abstract is required - please copy and paste the project abstract into the box above.'
                      : false
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.project_abstract?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <hr />
          <Col sm="12">
            <h5 className="fs-1">
              Does your project include the use of one or more of the following
              (check all that apply):
            </h5>
            <div className="text-base">
              Prior approval of an SRC, RB, IACUC, or IBC is{' '}
              <strong>required</strong>
            </div>
          </Col>
          <Col sm="12">
            <Form.Check
              type="checkbox"
              name="restricted_humans"
              id="restricted_humans"
              label="Humans (including student designed inventions/prototypes)"
              {...register('restricted_humans')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_animals"
              id="restricted_animals"
              label="Vertebrate Animals"
              {...register('restricted_animals')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_biohazard"
              id="restricted_biohazard"
              label="Potentially Hazardous Biological Agents"
              {...register('restricted_biohazard')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_hazard"
              id="restricted_hazard"
              label="Hazardous Chemicals, Activities, and Devices"
              {...register('restricted_hazard')}
            />
          </Col>

          <hr />
          <Col sm="12">
            <h5 className="fs-1">Final Checks:</h5>
            <div className="text-base">
              Items to be reviewed by the Team Leader or Adult Sponsor in
              collaboration with the student researchers:
            </div>
          </Col>
          <Col sm="12">
            <Form.Check
              type="checkbox"
              name="reviewed_ISEF_rules"
              id="reviewed_ISEF_rules"
            >
              <Form.Check.Input
                type="checkbox"
                isInvalid={errors.reviewed_ISEF_rules}
                {...register('reviewed_ISEF_rules', {
                  required: true
                })}
              />
              <Form.Check.Label>
                I have reveiwed the{' '}
                <a
                  href="https://www.societyforscience.org/isef/international-rules/rules-and-guidelines/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ISEF Rules and Guidelines
                </a>
                , including the science fair ethics statement.
              </Form.Check.Label>
            </Form.Check>
            {teamCategory === 'Senior Division' && (
              <>
                <Form.Check
                  type="checkbox"
                  name="reviewed_student_checklist"
                  id="reviewed_student_checklist"
                >
                  <Form.Check.Input
                    type="checkbox"
                    isInvalid={errors.reviewed_student_checklist}
                    {...register('reviewed_student_checklist', {
                      required: true
                    })}
                  />
                  <Form.Check.Label>
                    I have reveiwed the team's completed{' '}
                    <a
                      href="https://sspcdn.blob.core.windows.net/files/Documents/SEP/ISEF/2022/Forms/1A-Student-Checklist.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Student Checklist (1A) and Research Plan/Project Summary
                    </a>
                    .
                  </Form.Check.Label>
                </Form.Check>
              </>
            )}
            <Form.Check
              type="checkbox"
              name="reviewed_project_risk"
              id="reviewed_project_risk"
              isInvalid={errors.reviewed_project_risk}
              {...register('reviewed_project_risk', {
                required: true,
                onChange: () => {
                  trigger();
                }
              })}
              label="I have worked with the students and we have discussed the possible risks involved in the project."
            />
          </Col>

          <hr />
          <Col sm="12">
            <h5 className="fs-1">Upload Documents:</h5>
            <div className="text-base mb-3">
              Please attach all documents and forms here.
            </div>
            <div>
              <div {...getRootProps({ className: 'dropzone-area py-4' })}>
                <input {...getInputProps()} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">Drop your forms here</p>
                </Flex>
              </div>
            </div>
            <div>
              <div className="mt-3">
                {files.map(file => (
                  <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={file.path}
                  >
                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="ms-3 flex-1"
                    >
                      <div>
                        <h6>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{getSize(file.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                      <Button
                        variant="link"
                        className="text-danger fs-0"
                        onClick={() => removeFileHandler(file.path)}
                      >
                        Remove file
                      </Button>
                    </Flex>
                  </Flex>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

EventRegProjInfo.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
  teamCategory: PropTypes.string,
  trigger: PropTypes.func,
  files: PropTypes.array,
  setFiles: PropTypes.func
};
export default EventRegProjInfo;
