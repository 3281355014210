import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { teams_table, students_table } from 'configs/airtable';

// Create a context
const TeamsContext = createContext();

// Custom hook to use Teams context
export const useTeamsContext = () => useContext(TeamsContext);

export const TeamsProvider = ({ children }) => {
  const [teams, setTeams] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    console.log('Fetching data from Airtable');
    try {
      const teamsResponse = await teams_table
        .select({
          sort: [
            {
              field: 'TeamAutoNum',
              direction: 'asc'
            }
          ]
        })
        .all();
      const studentsResponse = await students_table.select().all();

      const teamsData = teamsResponse.map(record => record.fields);
      console.log('TeamsContext/teamsData', teamsData);
      setTeams(teamsData);

      const studentsData = studentsResponse.map(record => record.fields);
      // console.log('TeamsContext/studentsData', studentsData);
      setStudents(studentsData);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const updateTeam = async (teamRecordID, updatedFields) => {
    try {
      console.log('updating team', teamRecordID, updatedFields);
      const updatedTeam = await teams_table.update(teamRecordID, updatedFields);
      const updatedTeamIndex = teams.findIndex(
        team => team.pk_team_record_id === teamRecordID
      );
      const updatedTeams = [...teams];
      updatedTeams[updatedTeamIndex] = updatedTeam.fields;
      setTeams(updatedTeams);
    } catch (error) {
      console.error(error);
    }
  };

  const updateStudent = async (studentRecordID, updatedFields) => {
    try {
      const updatedStudent = await students_table.update(
        studentRecordID,
        updatedFields
      );
      const updatedStudentIndex = students.findIndex(
        student => student.pk_student_record_id === studentRecordID
      );
      const updatedStudents = [...students];
      updatedStudents[updatedStudentIndex] = updatedStudent.fields;
      setStudents(updatedStudents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // if (loading) {
  //   return <div>Loading teams data...</div>;
  // }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <TeamsContext.Provider
      value={{ teams, students, loading, updateTeam, updateStudent }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

TeamsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
