import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DeleteConfirmationModal from 'components/modals/DeleteConfirmationModal';
import SoftBadge from 'components/common/SoftBadge';
import { getUserRole } from 'helpers/api/auth.api.helper';

const TeamsTable = ({ teamsData, queryParsedTeamData }) => {
  console.log(teamsData);
  const [showModal, setShowModal] = useState();
  const [deleteTeam, setDeleteTeam] = useState({});

  const columns = [
    {
      accessor: 'registration_status',
      Header: 'Registration Status',
      Cell: rowData => {
        const { registration_status } = rowData.row.original;
        if (registration_status && registration_status != undefined) {
          switch (registration_status[0]) {
            case 'Missing Abstract':
            case 'Missing Forms':
              return (
                <SoftBadge pill bg="danger" className="me-2">
                  {registration_status}
                </SoftBadge>
              );

            case 'Pending Approval':
              return (
                <SoftBadge pill bg="warning" className="me-2">
                  {registration_status}
                </SoftBadge>
              );

            case 'Approved':
              return (
                <SoftBadge pill bg="success" className="me-2">
                  {registration_status}
                </SoftBadge>
              );
            default:
              return null;
          }
        } else {
          return null;
        }
      }
    },
    {
      accessor: 'team_num',
      Header: 'Team #',
      Cell: rowData => {
        const { team_record_id, team_num } = rowData.row.original;
        return <Link to={`/teams/${team_record_id}`}>{team_num}</Link>;
      }
    },

    {
      accessor: 'team_name',
      Header: 'Team Name'
    },
    {
      accessor: 'team_members',
      Header: 'Team Members'
    },
    {
      accessor: 'team_category',
      Header: 'Team Category'
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { team_record_id, team_num } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item as={Link} to={`/teams/${team_record_id}`}>
                View Team Details
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  setDeleteTeam({
                    name: `Team ${team_num}`,
                    recordID: team_record_id
                  });
                  setShowModal(true);
                }}
              >
                Delete Team
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <DeleteConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteItem={deleteTeam}
        setDeleteItem={setDeleteTeam}
        queryParsedTeamData={queryParsedTeamData}
      />
      <AdvanceTableWrapper
        columns={columns}
        data={teamsData}
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs="auto" sm={6} lg={7}>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  {getUserRole() == 'admin' ? 'All Teams' : 'Your Teams'}
                </h5>
              </Col>
              <Col xs="auto" sm={6} lg={5}>
                <AdvanceTableSearchBox table placeholder="Search for a team" />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

TeamsTable.propTypes = {
  teamsData: PropTypes.array,
  queryParsedTeamData: PropTypes.func
};

export default TeamsTable;
