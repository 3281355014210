import React from 'react';
import { Card, Col, Row, Image } from 'react-bootstrap';

import site_under_construction from 'assets/img/generic/construction.png';
import Flex from 'components/common/Flex';

const SiteConstruction = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col
            lg={12}
            xl={7}
            className="ps-lg-4 my-5 text-center text-lg-center"
          >
            <h2 className="display-3">FEATURE UNDER CONSTRUCTION</h2>
            <p className="lead">
              Our website is currently undergoing renovation as we are adding
              features to make it easier to look up and register for events. We
              appreciate your patience while we are working to enhance your
              experience.
            </p>
          </Col>
          <Col lg={12} xl={5}>
            <Flex justifyContent="center">
              <Image
                src={site_under_construction}
                fluid
                alt=""
                className="w-80"
              />
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SiteConstruction;
