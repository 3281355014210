import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import TeamMemberItem from './team-detail-member/TeamMemberItem';
const TeamDetailMemberBody = props => {
  console.log(props?.teamMembers);
  return (
    <div>
      <Table responsive="md">
        <thead>
          <tr className="align-middle">
            <th scope="col">Team Member Name</th>
            <th scope="col">Grade</th>
            <th scope="col">School</th>
            <th scope="col">Emergency Contact</th>
            <th scope="col"></th>
            <th scope="col" style={{ minWidth: '130px' }}></th>
          </tr>
        </thead>
        <tbody className="align-middle">
          {Object.keys(props.teamMembers).map(team_member_id => (
            <TeamMemberItem
              key={team_member_id}
              student_record_id={team_member_id}
              teamMember={props.teamMembers[team_member_id]}
              {...props}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

TeamDetailMemberBody.propTypes = {
  teamMembers: PropTypes.object
};

export default TeamDetailMemberBody;
