import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthCardLayout from 'layouts/AuthCardLayout';
import ForgetPasswordForm from 'components/authentication/ForgotPasswordForm';
import PasswordResetContent from 'components/authentication/PasswordResetContent';

const PasswordResetBody = props => {
  return (
    <>
      <h4 className="mb-0"> Forgot your password?</h4>
      <p className="mb-0">Enter your email and we'll send you a reset link.</p>
      <ForgetPasswordForm layout="card" setEmailSent={props.setEmailSent} />
    </>
  );
};
const ForgetPassword = () => {
  const [emailSent, setEmailSent] = useState({ to: '', sent: false });
  return (
    <AuthCardLayout>
      {emailSent.sent ? (
        <PasswordResetContent email={emailSent.to} />
      ) : (
        <PasswordResetBody setEmailSent={setEmailSent} />
      )}
    </AuthCardLayout>
  );
};

PasswordResetBody.propTypes = {
  setEmailSent: PropTypes.function
};

export default ForgetPassword;
