import React from 'react';
import {
  // Redirect,
  Route,
  Routes,
  Navigate
  // useLocation,
} from 'react-router-dom';
import { RequireAuth } from 'routes/AuthCheck';
// import AppContext from 'context/Context';

// import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
// import classNames from 'classnames';

import SiteConstruction from 'components/errors/SiteConstruction';
// import EventsCNYSEFRoutes from './EventsCNYSEFRoutes';
import EventRegistration from 'layouts/events/forms/registration/EventRegistration';

// Importing different route pages
import EventsRegistered from 'pages/events/EventsRegistered';
// import EventsList from 'pages/events/EventsList';
import EventsDetail from 'pages/events/EventsDetail';
import DataEntryRoutes from 'routes/data-entry';

const EventsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SiteConstruction />} />
      <Route path="/:event_record_id" element={<EventsDetail />} />
      <Route
        path="/:event_record_id/register"
        element={
          <RequireAuth>
            <EventRegistration />
          </RequireAuth>
        }
      />
      {/* Route for data entry */}
      <Route
        path="/:event_record_id/data-entry/*"
        element={
          <RequireAuth>
            <DataEntryRoutes />
          </RequireAuth>
        }
      />
      {/* Route for registered events */}
      <Route
        path="/registered-events"
        element={
          <RequireAuth>
            <EventsRegistered />
          </RequireAuth>
        }
      />

      {/* <Route path="cnysef/*" element={<EventsCNYSEFRoutes />} /> */}
      <Route path="*" element={<Navigate to="/errors/404" />} />
    </Routes>
  );
};

export default EventsRoutes;
