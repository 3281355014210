import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { lightBlue } from '@mui/material/colors'; // Import the red color from MUI

export const ScoreTable = ({ division, scores }) => {
  // Function to convert number to 2 decimal places
  const convertToTwoDecimal = num => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  return (
    <Box mb={4}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: 'center', fontWeight: 'bold' }}
      >
        {division}
      </Typography>
      {scores?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Team Members</TableCell>
                <TableCell>Team School</TableCell>
                <TableCell>Bridge Weight (g)</TableCell>
                <TableCell>Breaking Load (kg)</TableCell>
                <TableCell>Bridge Efficiency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index < 4 && item.calculated_score > 0
                        ? lightBlue[50] // Subtle red highlight for Top 4 teams
                        : index % 2 === 0
                        ? 'grey.100' // Alternating row colors
                        : 'white',
                    fontWeight:
                      index < 4 && item.calculated_score > 0 ? 700 : 'normal' // Bold text for Top 4 teams
                  }}
                >
                  <TableCell>
                    {item.calculated_score > 0 ? index + 1 : 'DNQ'}
                  </TableCell>
                  <TableCell>{item.team_num_name}</TableCell>
                  <TableCell>{item.team_members}</TableCell>
                  <TableCell>{item.lookup_team_school_manual}</TableCell>
                  <TableCell>{item.bridge_weight}</TableCell>
                  <TableCell>{item.max_load}</TableCell>
                  <TableCell>
                    {convertToTwoDecimal(item.calculated_score)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" textAlign="center" gutterBottom>
          No {division} scores have been submitted yet.
        </Typography>
      )}
    </Box>
  );
};

ScoreTable.propTypes = {
  division: PropTypes.string.isRequired,
  scores: PropTypes.array.isRequired
};
