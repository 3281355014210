import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const TeamDetailCard = props => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Flex direction="row" justifyContent="between" wrap="nowrap">
          <h5 className="mb-0">
            {props.cardTitle} {props?.pill}
          </h5>
          {props.edit_button_text && (
            <IconButton
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="pencil-alt"
              onClick={() => {
                props.setEditMode(true);
              }}
            >
              {props.edit_button_text}
            </IconButton>
          )}
        </Flex>
      </Card.Header>
      <Card.Body
        className={classNames('bg-light border-top', {
          'p-0': props['no-padding']
        })}
      >
        {props.loading && (
          <p className="p-1 pt-2 text-center">
            Loading {props.section_name} info...
          </p>
        )}
        {!props.loading && props.children}
      </Card.Body>
      {props.editMode && props.setEditMode && (
        <Card.Footer className="border-top text-end">
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              props.setEditMode(false);
            }}
          >
            Discard Changes
          </Button>
          {props.isSubmitting ? (
            <Button
              variant="falcon-default"
              size="sm"
              className="ms-2"
              disabled
            >
              Saving changes...
            </Button>
          ) : (
            <IconButton
              className="ms-2"
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="check"
              onClick={() => {
                props.saveRef.current.requestSubmit();
              }}
              // type="submit"
              disabled={!props.isFormValid}
            >
              Save Changes
            </IconButton>
          )}
          {/* <Card.Footer className="border-top text-end">
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              setEditMode(false);
            }}
          >
            Discard Changes
          </Button>

          {isSubmitting ? (
            <Button
              variant="falcon-default"
              size="sm"
              className="ms-2"
              disabled
            >
              Saving changes...
            </Button>
          ) : (
            <IconButton
              className="ms-2"
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="check"
              type="submit"
              disabled={!isValid}
            >
              Save Changes
            </IconButton>
          )}
        </Card.Footer> */}
        </Card.Footer>
      )}
    </Card>
  );
};

TeamDetailCard.propTypes = {
  children: PropTypes.node,
  cardTitle: PropTypes.string.isRequired,
  'no-padding': PropTypes.bool,
  loading: PropTypes.bool,
  section_name: PropTypes.string,
  pill: PropTypes.node,
  edit_button_text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  saveRef: PropTypes.object,
  isFormValid: PropTypes.bool,
  isSubmitting: PropTypes.bool
};

export default TeamDetailCard;
