import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import API from 'configs/api';
import styles from './EventRegistration.module.css';

import EventRegFooter from './EventRegFooter';
import EventRegHeader from './EventRegHeader';
import EventRegProjInfo from './EventRegProjInfo';
import EventRegStudent from './EventRegStudent';
import EventRegTeamInfo from './EventRegTeamInfo';
import EventRegSuccess from './EventRegSuccess';
import { getRecordData } from 'helpers/api/api.helper';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
// import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { storage } from 'configs/firebase';

const initialFormState = {
  team_name: '',
  kit_requested: null,
  students: [
    {
      first_name: '',
      last_name: '',
      gender: '',
      school: '',
      emergency_first_name: '',
      emergency_last_name: '',
      emergency_phone_number: '',
      emergency_email: ''
    }
  ]
};

const EventRegistration = () => {
  const { event_record_id } = useParams();
  const [event, set_event] = useState(null); // event state

  // useEffect to retrieve event information from API server and set to event state
  useEffect(() => {
    // get event information from API server
    const get_event_info = async () => {
      try {
        const { data } = await getRecordData('events', event_record_id);
        console.log(data);
        set_event(data);
      } catch (e) {
        console.error(e);
      }
    };

    try {
      get_event_info();
    } catch (err) {
      console.error(err);
    }
  }, [event_record_id]);

  // useEffect to retrieve list of schools from API server
  const [schools, setSchools] = useState([]); // schools state
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const {
          data: { records_data }
        } = await API.get('/schools?view=API_VIEW');
        setSchools(records_data);
        console.log('School List: ', records_data);
      } catch (e) {
        console.error(e);
      }
    };

    try {
      fetchSchools();
    } catch (err) {
      console.error(err);
    }
  }, []);

  //Event Registration State Handler
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    // trigger,
    reset,
    control,
    formState: { isSubmitted, isValid, isValidating, errors }
  } = useForm({
    defaultValues: initialFormState,
    mode: 'all'
  });

  // Form field states
  const kit_requested_value = watch('kit_requested');
  const [question_team_division, setQuestionTeamDivision] = useState(null); // state for team division question
  const [continue_registration, setContinueRegistration] = useState(null); // state for continue registration button

  // File upload state handler
  // const [files, setFiles] = useState([]);

  // Submit state
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'students'
  });

  // Submit handler
  const onSubmit = async form_data => {
    setSubmitting(true);
    console.log(form_data);
    try {
      const server_data = await API.post(
        `/event/${event_record_id}/register`,
        form_data
      );
      console.log(server_data);
      console.log(server_data.statusText);
      console.log(server_data.data);

      // if status is 201, then set submitSuccessful to true
      if (server_data.status === 200 || server_data.status === 201) {
        setSubmitSuccessful(true);
      } else {
        toast.error('There was an error submitting your registration.', {
          position: 'bottom-center'
        });
        console.log('Error', server_data);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
        console.log(err.response.data);
        toast.error(
          err.response.data.message ||
            'There was an error submitting your registration.',
          {
            position: 'bottom-center'
          }
        );
      } else {
        console.error(err);
        toast.error(
          err.message || 'There was an error submitting your registration.',
          {
            position: 'bottom-center'
          }
        );
      }
      setSubmitSuccessful(false);
    }
    setSubmitting(false);
  };

  // Error handler
  const onError = errors => {
    console.log(errors);
    console.log(getValues('project_category'));
    reset(initialFormState);
  };

  // Open Robotics Purchasing Links
  const openPurchaseLinks = () => {
    const juniorLink = 'https://a.co/d/9B31vRA';
    const seniorLink = 'https://a.co/d/03YjKCt';
    const additionalLink = 'https://a.co/d/ailcwvk';

    if (question_team_division === 'junior') {
      window.open(juniorLink, '_blank');
    } else if (question_team_division === 'senior') {
      window.open(seniorLink, '_blank');
    }
    setTimeout(() => {
      window.open(additionalLink, '_blank');
    }, 1000);
  };

  return submitSuccessful && isSubmitted ? (
    <EventRegSuccess
      reset={reset}
      event_record_id={event_record_id}
      event={event}
    />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Row className="g-3">
        <Col xs={12}>
          <EventRegHeader
            isSubmitting={isSubmitting}
            isValid={isValid}
            event_name={event?.event_name}
          />
        </Col>
        {event?.kits_provided && (
          <Col sx={12}>
            <Card>
              <Card.Body>
                <Card.Title className="text-center mb-3">
                  Would you like to request a kit for this event?
                </Card.Title>
                <Form.Group className="text-center mb-4">
                  <div>
                    <Button
                      variant="outline-primary"
                      className="me-2"
                      onClick={() => setValue('kit_requested', 1)}
                      active={kit_requested_value === 1}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => setValue('kit_requested', 0)}
                      active={kit_requested_value === 0}
                    >
                      No
                    </Button>
                  </div>
                </Form.Group>

                {/* Conditional alert message */}
                {getValues('kit_requested') === 1 && (
                  <>
                    <Card.Title className="text-center mb-3">
                      What division will your team be participating in?
                    </Card.Title>
                    <Form.Group className="text-center mb-4">
                      <div>
                        <Button
                          variant="outline-primary"
                          className={`me-2 ${styles.kitButton}`}
                          onClick={() => setQuestionTeamDivision('junior')}
                          active={question_team_division === 'junior'}
                        >
                          <span>Junior Division (Grades 4-8)</span>
                          {event?.kits_remaining_jr !== undefined && (
                            <div
                              className={styles.kitsRemaining}
                              style={{
                                fontSize: 'smaller',
                                color:
                                  question_team_division === 'junior'
                                    ? 'white'
                                    : '#696969'
                              }}
                            >
                              Kits Remaining: {event?.kits_remaining_jr}
                            </div>
                          )}
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={() => setQuestionTeamDivision('senior')}
                          active={question_team_division === 'senior'}
                        >
                          <span>Senior Division (Grades 9-12)</span>
                          {event?.kits_remaining_sr !== undefined && (
                            <div
                              style={{
                                fontSize: 'smaller',
                                color:
                                  question_team_division === 'senior'
                                    ? 'white'
                                    : '#696969'
                              }}
                            >
                              Kits Remaining: {event?.kits_remaining_sr}
                            </div>
                          )}
                        </Button>
                      </div>
                    </Form.Group>
                    {/* Conditional alert for kit availability */}
                    {((question_team_division === 'junior' &&
                      event?.kits_remaining_jr === 0) ||
                      (question_team_division === 'senior' &&
                        event?.kits_remaining_sr === 0)) && (
                      <Alert variant="danger" className="text-center">
                        <p>
                          Sorry, we ran out of kits for your division. You may
                          still register to participate in this event, but you
                          will need to purchase your own kit. You can find the
                          purchasing links{' '}
                          <a href="#" onClick={openPurchaseLinks}>
                            here
                          </a>
                          .
                        </p>
                        <p>
                          Would you still like to register? By clicking{' '}
                          <strong>Yes</strong>, you understand that you will
                          need to purchase your own kit for this event.
                        </p>
                        <Button
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => setContinueRegistration(true)}
                          active={continue_registration === true}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="outline-secondary"
                          onClick={() => setContinueRegistration(false)}
                          active={continue_registration === false}
                        >
                          No
                        </Button>
                      </Alert>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}

        {/* {(kit_requested_value === 0 ||
          event?.kits_provided == null ||
          event?.kits_provided == undefined ||
          continue_registration ||
          (question_team_division === 'junior' &&
            event?.kits_remaining_jr > 0) ||
          (question_team_division === 'senior' &&
            event?.kits_remaining_sr > 0)) && <></>} */}

        <>
          {/* If event is not CNYSEF then render EventRegTeamInfo */}
          {event?.event_challenge !== 'CNYSEF' && (
            <Col xs={12}>
              <EventRegTeamInfo
                register={register}
                setValue={setValue}
                errors={errors}
              />
            </Col>
          )}
          <Col xs={12}>
            <EventRegStudent
              fields={fields}
              register={register}
              setValue={setValue}
              append={append}
              remove={remove}
              control={control}
              errors={errors}
              defaultStudentValues={initialFormState.students[0]}
              schools={schools}
            />
          </Col>
          {/* If event_challenge is CNYSEF, render EventCNYSEFProjectInfo */}
          {event?.event_challenge === 'CNYSEF' && (
            <Col xs={12}>
              <EventRegProjInfo
                register={register}
                errors={errors}
                control={control}
                // trigger={trigger}
                // files={files}
                // setFiles={setFiles}
              />
            </Col>
          )}
          <Col xs={12}>
            <EventRegFooter
              isSubmitting={isSubmitting}
              isValidating={isValidating}
              isValid={isValid}
              errors={errors}
            />
          </Col>
        </>
      </Row>
    </Form>
  );
};

export default EventRegistration;
