import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

export const TeamMemberFormInput = ({
  label,
  name,
  errors,
  type = 'text',
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  touchedFields
}) => {
  // If type is select
  if (type === 'select') {
    return (
      <Form.Group as={Row} className="mb-3" {...formGroupProps}>
        <Form.Label column xs={12} sm={3}>
          {label}
        </Form.Label>
        <Col xs={12} sm={9}>
          <Form.Select
            {...formControlProps}
            isInvalid={errors[name]}
            // isValid is true if name exists in dirtyFields

            isValid={touchedFields[name] && !errors[name]}
          >
            <option value="">{placeholder}</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  }

  // If type is text (default)
  return (
    <Form.Group as={Row} className="mb-3" {...formGroupProps}>
      <Form.Label column xs={12} sm={3}>
        {label}
      </Form.Label>
      <Col xs={12} sm={9}>
        <Form.Control
          type={type}
          placeholder={placeholder}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={touchedFields[name] && !errors[name]}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

TeamMemberFormInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  validatedMessage: PropTypes.string,
  errors: PropTypes.object,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  touchedFields: PropTypes.object
};
