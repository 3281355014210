import React from 'react';
import { Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

import lottieAnimation from 'assets/lotties/loading_hourglass.json';

const DataLoadingLottieCard = ({ title }) => {
  return (
    <Col className="text-center">
      <div className="wizard-lottie-wrapper">
        <div className="mx-auto">
          <Lottie
            loop
            animationData={lottieAnimation}
            play
            style={{ height: 150 }}
            // rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </div>
        <Card.Title className="mb-3">{title}</Card.Title>
      </div>
    </Col>
  );
};

DataLoadingLottieCard.propTypes = {
  title: PropTypes.string
};

export default DataLoadingLottieCard;
