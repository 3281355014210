import {
  bridges_scores_table,
  events_table,
  special_awards_table
} from 'configs/airtable';

export const getBridgesScoresByYear = async eventYear => {
  return bridges_scores_table
    .select({
      filterByFormula: `FIND("${eventYear}", {lookup_event_id}) > 0`,
      sort: [{ field: 'calculated_score', direction: 'desc' }]
    })
    .all();
};

export const updateSpecialAward = async (awardRecordID, updatedFields) => {
  try {
    console.log('updating special award', awardRecordID, updatedFields);
    const updatedAward = await special_awards_table.update(
      awardRecordID,
      updatedFields
    );
    return updatedAward;
  } catch (error) {
    console.error(error);
  }
};

export const getSpecialAwardTeams = async awardRecordID => {
  try {
    const awardRecord = await special_awards_table.find(awardRecordID);
    return awardRecord.fields.teams;
  } catch (error) {
    console.error(error);
  }
};

export const togglePublishedScore = async eventID => {
  try {
    // First, find out if the score for the event has been published
    const eventRecord = await bridges_scores_table.find(eventID);
    await bridges_scores_table.update(eventID, {
      publish_scores: !eventRecord.fields.publish_scores
    });
  } catch (error) {
    console.error(error);
  }
};

export const getEventDetails = async eventID => {
  try {
    const eventRecord = await events_table.find(eventID);
    return eventRecord.fields;
  } catch (error) {
    console.error(error);
  }
};
