import API from 'configs/api';

export const getEventTeams = async eventID => {
  return API.get(`/event/${eventID}/teams?sortParams=team_num:asc`);
};

export const updateScoreEntryData = async (eventID, teamID, data) => {
  return API.post(`/dataentry/${eventID}/${teamID}`, data);
};

export const getBridgeJuniorScores = async () => {
  return API.get('/dataentry/scores/2023bridges/junior-division');
};

export const getBridgeSeniorScores = async () => {
  return API.get('/dataentry/scores/2023bridges/senior-division');
};

export const getRoboticsJuniorScores = async () => {
  return API.get('/dataentry/scores/junior-division');
};

export const getRoboticsSeniorScores = async () => {
  return API.get('/dataentry/scores/senior-division');
};

export const getRocketsJuniorScores = async () => {
  return API.get('/dataentry/scores/2023rtc/junior-division');
};

export const getRocketsSeniorScores = async () => {
  return API.get('/dataentry/scores/2023rtc/senior-division');
};

export const getSteamboatJuniorScores = async () => {
  return API.get('/dataentry/scores/2023steamboat/junior-division');
};

export const getSteamboatSeniorScores = async () => {
  return API.get('/dataentry/scores/2023steamboat/senior-division');
};
