import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import API from 'configs/api';

// Importing layouts and components
import PasswordResetSuccessfulContent from 'layouts/authentication/PasswordResetSuccessfulContent';

const PasswordResetForm = ({ hasLabel, token }) => {
  const [submitStatus, setSubmittingStatus] = useState(false);
  //React Hook Form API
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    trigger,
    watch,
    reset
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldFocusError: true
  });

  // Handlers
  const onSubmitData = async form_data => {
    //do somethin
    //only send password from form_data
    console.log('the password being sent is: ', form_data.password);
    try {
      await API.put(`/auth/resetpassword/${token}`, {
        password: form_data.password
      });
      setSubmittingStatus('success');
      reset();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.error(error);
      }
      setSubmittingStatus(false);
    }
  };

  return submitStatus == 'success' ? (
    <PasswordResetSuccessfulContent titleTag="h3" />
  ) : (
    <>
      <h3>Reset password</h3>
      <Form
        className={classNames('mt-3', { 'text-left': hasLabel })}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>New Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'New Password' : ''}
            name="password"
            type="password"
            isInvalid={errors.password}
            {...register('password', {
              required: 'You must specify a password',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              onBlur: async () => {
                await trigger('password');
              }
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            name="confirmPassword"
            type="password"
            isInvalid={errors.confirmPassword}
            {...register('confirmPassword', {
              required: 'You must specify a password',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              validate: value =>
                value === watch('password') || 'The password do not match',
              onChange: async () => {
                await trigger('confirmPassword');
              }
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.confirmPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="submit" className="w-100" disabled={!isValid}>
          {submitStatus ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            >
              <span className="visually-hidden">Logging in...</span>
            </Spinner>
          ) : (
            'Set password'
          )}
        </Button>
      </Form>
    </>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
  token: PropTypes.string
};

export default PasswordResetForm;
