import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from 'components/common/ActionButton';
import DeleteStudentModal from 'components/modals/DeleteStudentModal';
import { TeamMemberEditModal } from './TeamMemberEditModal';
import { deleteRecord } from 'helpers/api/api.helper';

// Action buttons appear upon hover
const Actions = ({ setShowEditModal, setShowDeleteModal }) => (
  <div className="text-end">
    <ActionButton
      icon="edit"
      title="Edit"
      variant="light"
      className="border-300 me-1 text-600"
      size="sm"
      onClick={() => setShowEditModal(true)}
    />
    <ActionButton
      icon="trash-alt"
      title="Remove student from team"
      variant="light"
      className="border-300 me-1 text-600"
      size="sm"
      onClick={() => {
        setShowDeleteModal(true);
      }}
    />
  </div>
);

// Detail View
const TeamMemberItemDetail = ({ teamMember, ...props }) => {
  return (
    <tr className="">
      <td>{teamMember.full_name}</td>
      <td>{teamMember.grade}</td>
      <td>{teamMember.school}</td>
      <td>{teamMember.emergency_full_name}</td>
      <td>
        {teamMember.emergency_phone_number}
        <br />
        {teamMember.emergency_email}
      </td>
      <td>
        <Actions {...props} />
      </td>
    </tr>
  );
};

const TeamMemberItem = props => {
  // View state manager
  const [showEditModal, setShowEditModal] = React.useState(false); // show edit modal when user clicks edit button
  const [showDeleteModal, setShowDeleteModal] = React.useState(false); // show delete modal when user clicks delete button

  // Handler function to remove team member from team
  const { setTeamMembers } = props;
  const removeTeamMember = async team_member_id => {
    try {
      console.log(`Removing team member from team...`);
      await deleteRecord('students', team_member_id);
      setTeamMembers(prevTeamMembers => {
        let newTeamMembers = { ...prevTeamMembers };
        delete newTeamMembers[team_member_id];
        return newTeamMembers;
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <DeleteStudentModal
        {...props}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        removeTeamMember={removeTeamMember}
      />
      {showEditModal && (
        <TeamMemberEditModal
          {...props}
          submitType="edit"
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      )}
      <TeamMemberItemDetail
        {...props}
        setShowEditModal={setShowEditModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

// Primary Component PropType Validation
TeamMemberItem.propTypes = {
  setTeamMembers: PropTypes.func.isRequired
};

// Team Member Detail View PropType Validation
TeamMemberItemDetail.propTypes = {
  student_record_id: PropTypes.string.isRequired,
  teamMember: PropTypes.object.isRequired,
  setShowEditModal: PropTypes.func.isRequired
  // removeTeamMember: PropTypes.func
};

Actions.propTypes = {
  student_record_id: PropTypes.string.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  removeTeamMember: PropTypes.func
};

export default TeamMemberItem;
