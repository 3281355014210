import React, { useEffect, useState } from 'react';

// import { Card, Col, Nav, Row, Flex } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

import Error500 from 'components/errors/Error500';
import PageHeader from 'components/common/PageHeader';
import DataLoadingLottieCard from 'components/common/DataLoadingLottieCard';
import TeamsTable from 'layouts/teams/TeamsTable';
import { getParsedTeamTableData } from 'helpers/api/teams.api.helper';

const TeamsLayout = () => {
  const [serverError, setServerError] = useState(false);
  const [teamsData, setTeamsData] = useState();
  const [loaded, setLoaded] = useState(false);

  const queryParsedTeamData = async () => {
    const teams_parsed_data = await getParsedTeamTableData();
    setTeamsData(teams_parsed_data);
  };

  useEffect(async () => {
    const errorTimer = setTimeout(() => {
      setServerError(true);
    }, process.env.REACT_APP_SERVER_TIMEOUT);

    try {
      await queryParsedTeamData();
      clearTimeout(errorTimer);
    } catch (err) {
      setServerError(true);
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (teamsData) {
      setLoaded(true);
    }
  }, [teamsData]);

  return (
    <>
      <PageHeader
        title="Teams"
        description="Manage your registered teams here."
        className="mb-3"
      />

      {serverError ? (
        <Error500 />
      ) : loaded ? (
        <TeamsTable
          teamsData={teamsData}
          queryParsedTeamData={queryParsedTeamData}
        />
      ) : (
        <Card>
          <Card.Body>
            <DataLoadingLottieCard title="Loading your teams..." />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default TeamsLayout;
