import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RegWizardInput from './RegWizardInput';
import { AuthWizardContext } from 'context/Context';
import { Col, Row } from 'react-bootstrap';

const PersonalForm = ({ register, errors, trigger, setValue }) => {
  const { tbFields, set_tbFields } = useContext(AuthWizardContext);
  const phoneNumberFormatter = e => {
    let formattedInputValue = e.target.value;
    // if input value is falsy, clear input and move on
    if (!formattedInputValue) {
      setValue('phone_number', '');
      return;
    }

    // clean the input for any non-digit values
    let phoneNumber = formattedInputValue.replaceAll(/[^\d]/gi, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) {
      setValue('phone_number', phoneNumber);
    } else if (phoneNumberLength < 7) {
      setValue(
        'phone_number',
        `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
      );
    } else {
      setValue(
        'phone_number',
        `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`
      );
    }
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <RegWizardInput
          label="First Name"
          name="first_name"
          errors={errors}
          isFieldBlurred={tbFields.first_name}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('first_name', {
              required: true,
              pattern: {
                value: /[A-zÀ-ú]{2,}/i,
                message: 'Please enter a valid first name.'
              },
              onBlur: async () => {
                if (await trigger('first_name')) {
                  set_tbFields(prevState => {
                    return { ...prevState, first_name: true };
                  });
                }
              }
            })
          }}
        />
        <RegWizardInput
          label="Last Name"
          name="last_name"
          isFieldBlurred={tbFields.last_name}
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('last_name', {
              required: true,
              pattern: {
                value: /[A-zÀ-ú]{2,}/i,
                message: 'Please enter a valid last name.'
              },
              onBlur: async () => {
                if (await trigger('last_name')) {
                  set_tbFields(prevState => {
                    return { ...prevState, last_name: true };
                  });
                }
              }
            })
          }}
        />
      </Row>

      <RegWizardInput
        type="tel"
        label="Phone"
        name="phone_number"
        isFieldBlurred={tbFields.phone_number}
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('phone_number', {
            required: true,
            minLength: 14,
            onChange: phoneNumberFormatter,
            onBlur: async () => {
              if (await trigger('phone_number')) {
                set_tbFields(prevState => {
                  return { ...prevState, phone_number: true };
                });
              }
            }
          })
        }}
      />

      <RegWizardInput
        label="Company/School (Optional)"
        name="address_company"
        isFieldBlurred={tbFields.address_company}
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('address_company', {
            onBlur: async () => {
              if (await trigger('address_company')) {
                set_tbFields(prevState => {
                  return { ...prevState, address_company: true };
                });
              }
            }
          })
        }}
      />

      <RegWizardInput
        label="Address 1"
        name="address_1"
        isFieldBlurred={tbFields.address_1}
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('address_1', {
            required: true,
            onBlur: async () => {
              if (await trigger('address_1')) {
                set_tbFields(prevState => {
                  return { ...prevState, address_1: true };
                });
              }
            }
          })
        }}
      />

      <RegWizardInput
        label="Address 2 (Optional)"
        name="address_2"
        isFieldBlurred={tbFields.address_2}
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('address_2', {
            onBlur: async e => {
              if (e.target.value != '' && (await trigger('address_2'))) {
                set_tbFields(prevState => {
                  return { ...prevState, address_2: true };
                });
              }
            }
          })
        }}
      />

      <Row className="g-3 mb-3">
        <RegWizardInput
          label="City"
          name="city"
          isFieldBlurred={tbFields.city}
          errors={errors}
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('city', {
              required: true,
              onBlur: async () => {
                if (await trigger('city')) {
                  set_tbFields(prevState => {
                    return { ...prevState, city: true };
                  });
                }
              }
            })
          }}
        />

        <RegWizardInput
          type="select"
          label="State"
          name="state"
          placeholder="Select your state..."
          isFieldBlurred={tbFields.state}
          errors={errors}
          options={[
            'AL',
            'AK',
            'AS',
            'AZ',
            'AR',
            'CA',
            'CO',
            'CT',
            'DE',
            'DC',
            'FM',
            'FL',
            'GA',
            'GU',
            'HI',
            'ID',
            'IL',
            'IN',
            'IA',
            'KS',
            'KY',
            'LA',
            'ME',
            'MH',
            'MD',
            'MA',
            'MI',
            'MN',
            'MS',
            'MO',
            'MT',
            'NE',
            'NV',
            'NH',
            'NJ',
            'NM',
            'NY',
            'NC',
            'ND',
            'MP',
            'OH',
            'OK',
            'OR',
            'PW',
            'PA',
            'PR',
            'RI',
            'SC',
            'SD',
            'TN',
            'TX',
            'UT',
            'VT',
            'VI',
            'VA',
            'WA',
            'WV',
            'WI',
            'WY'
          ]}
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('state', {
              required: true,
              onBlur: async () => {
                if (await trigger('state')) {
                  set_tbFields(prevState => {
                    return { ...prevState, state: true };
                  });
                }
              }
            })
          }}
        />

        <RegWizardInput
          label="Zip Code"
          name="zip_code"
          isFieldBlurred={tbFields.zip_code}
          errors={errors}
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('zip_code', {
              required: true,
              minLength: 5,
              onChange: async () => {
                if (await trigger('zip_code')) {
                  set_tbFields(prevState => {
                    return { ...prevState, zip_code: true };
                  });
                }
              },
              onBlur: async () => {
                if (await trigger('zip_code')) {
                  set_tbFields(prevState => {
                    return { ...prevState, zip_code: true };
                  });
                }
              }
            })
          }}
        />
      </Row>
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  trigger: PropTypes.func
};

export default PersonalForm;
