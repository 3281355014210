import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row, Spinner } from 'react-bootstrap';

const EventRegHeader = ({ isSubmitting, isValid, event_name }) => {
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {event_name && event_name} Event Registration
            </h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-primary"
              disabled={!isValid}
              type="submit"
            >
              {isSubmitting === true ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                >
                  <span className="visually-hidden">Registering team...</span>
                </Spinner>
              ) : (
                'Register Team'
              )}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventRegHeader.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  event_name: PropTypes.string
};

export default EventRegHeader;
