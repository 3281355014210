import EventDetailHeader from './event-detail/EventDetailHeader';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getEventData,
  getMyRegisteredTeams,
  toggleTeamWithdrawnStatus
} from 'helpers/api/teams.api.helper';
import EventDetailTeamsList from './event-detail/EventDetailTeamsList';
import { isLoggedIn } from 'helpers/api/auth.api.helper';

const EventsDetail = () => {
  const { event_record_id } = useParams();
  console.log('Fetching event data for event ID:', event_record_id);
  const logged_in = isLoggedIn();
  const [event, setEvent] = useState(null);
  const [myRegisteredTeams, setMyRegisteredTeams] = useState(null);

  // useEffect to fetch event record from API using event_record_id
  useEffect(() => {
    const fetchEventRecord = async () => {
      try {
        const res = await getEventData(event_record_id);
        if (res.status > 300) {
          console.log('eventsapihelper oh hell naw');
          throw new Error('SERVER_ERROR');
        } else {
          const { data } = res;
          console.log('event data:', data);
          console.log(
            'event date',
            data.record_data.event_date,
            typeof data.record_data.event_date
          );
          // convert event_date to Date object
          const event_date = new Date(data.record_data.event_date);
          // set event_month and event_day
          const event_month = event_date.toLocaleString('default', {
            month: 'short' // 'Jan'
          });
          const event_day = event_date.getDate();
          console.log("event's month:", event_month);
          console.log("event's day:", event_day);

          // set event state
          setEvent({ ...data.record_data, event_month, event_day });
        }
      } catch (err) {
        err.response ? console.log(err.response) : console.error(err);
        throw err;
      }
    };
    fetchEventRecord();
  }, [event_record_id]);

  // useEffect to fetch teams registered for event by user
  useEffect(() => {
    const fetchTeamsRegistered = async () => {
      try {
        const res = await getMyRegisteredTeams(event_record_id);
        if (res.status > 300) {
          console.log('eventsapihelper oh hell naw');
        } else {
          const { data } = res;
          console.log(data.records_data);
          setMyRegisteredTeams(data.records_data);
        }
      } catch (err) {
        err.response ? console.log(err.response) : console.error(err);
      }
    };
    logged_in && fetchTeamsRegistered();
  }, [event_record_id]);

  const handleToggleTeamWithdrawnStatus = async team_record_id => {
    try {
      console.log(
        `Withdrawing team ${team_record_id} from event ${event_record_id}`
      );
      await toggleTeamWithdrawnStatus(team_record_id);
      let removedTeam = myRegisteredTeams.find(
        team => team.pk_team_record_id === team_record_id
      );
      removedTeam.withdrawn = !removedTeam.withdrawn;
      console.log('removedTeam:', removedTeam);
      const filteredState = myRegisteredTeams.filter(
        team => team.pk_team_record_id !== team_record_id
      );
      // get index of the last team in filteredState withdrawn key exists
      var lastRegisteredTeamIndex = 0;
      for (var i = 0; i < filteredState.length; i++) {
        console.log(
          `filteredState[${i}].withdrawn:`,
          filteredState[i].withdrawn
        );
        if (i === 0 && filteredState[0].withdrawn != undefined) {
          lastRegisteredTeamIndex = -1;
          break;
        } else if (filteredState[i].withdrawn == undefined) {
          lastRegisteredTeamIndex = i;
        }
      }
      console.log('lastRegisteredTeamIndex:', lastRegisteredTeamIndex);

      if (removedTeam.withdrawn) {
        filteredState.push(removedTeam);
      } else {
        filteredState.splice(lastRegisteredTeamIndex + 1, 0, removedTeam);
      }
      setMyRegisteredTeams(filteredState);
    } catch (err) {
      err.response ? console.log(err.response) : console.error(err);
    }
  };

  return (
    <>
      <EventDetailHeader event={event} />
      {logged_in && myRegisteredTeams?.length > 0 && (
        <EventDetailTeamsList
          isEventAFair={event?.event_challenge.includes('CNYSEF')}
          teams={myRegisteredTeams}
          toggleTeamWithdrawnStatus={handleToggleTeamWithdrawnStatus}
        />
      )}
    </>
  );
};

export default EventsDetail;
