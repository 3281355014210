import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RegWizardInput from './RegWizardInput';
import { Col, Row } from 'react-bootstrap';
import API from 'configs/api';
import { AuthWizardContext } from 'context/Context';
import * as EmailValidator from 'email-validator';

const RegAccountForm = ({
  register,
  errors,
  setError,
  clearErrors,
  watch,
  trigger
}) => {
  const { tbFields, set_tbFields, setUniqueEmail } =
    useContext(AuthWizardContext);
  // Email validation with backend server:
  const serverValidateEmail = async event => {
    const triggerEmailValidation = await trigger('email');
    console.log(event.target.value);
    if (triggerEmailValidation) {
      try {
        const server_data = await API.get(
          `/auth/register/?email=${event.target.value}`
        );
        if (server_data.status === 200) {
          clearErrors('email');
          setUniqueEmail(true);
          set_tbFields(prevState => {
            return { ...prevState, email: true };
          });
        }
      } catch (error) {
        if (error.response) {
          console.log('error response data', error.response.data);
          console.log(error.response.status);
          if (error.response.status >= 400) {
            setUniqueEmail(false);
            setError('email', { message: error.response.data.message });
          }
        } else {
          console.error(error);
        }
      }
    }
  };

  return (
    <>
      <RegWizardInput
        type="email"
        isFieldBlurred={tbFields.email}
        validatedMessage="Email looks good!"
        errors={errors}
        label="Email*"
        name="email"
        formGroupProps={{ className: 'mb-2', controlId: 'email' }}
        formControlProps={{
          ...register('email', {
            required: 'Email field is required',
            onBlur: serverValidateEmail,
            validate: value =>
              EmailValidator.validate(value) || 'Please enter a valid email.'
          })
        }}
      />

      <Row className="g-3 mb-3">
        <RegWizardInput
          type="password"
          isFieldBlurred={tbFields.password}
          errors={errors}
          label="Password*"
          name="password"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('password', {
              required: 'You must specify a password',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              onBlur: () => {
                set_tbFields(prevState => {
                  return { ...prevState, password: true };
                });
              }
            })
          }}
        />
        <RegWizardInput
          type="password"
          isFieldBlurred={tbFields.confirmPassword}
          errors={errors}
          label="Confirm Password*"
          name="confirmPassword"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('confirmPassword', {
              required: 'Confirm Password field is required',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              validate: value =>
                value === watch('password') || 'The password do not match',
              onChange: async () => {
                if (await trigger('confirmPassword')) {
                  set_tbFields(prevState => {
                    return { ...prevState, confirmPassword: true };
                  });
                }
              },
              onBlur: () => {
                set_tbFields(prevState => {
                  return { ...prevState, confirmPassword: true };
                });
              }
            })
          }}
        />
      </Row>
    </>
  );
};

RegAccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  watch: PropTypes.func,
  trigger: PropTypes.func
};

export default RegAccountForm;
