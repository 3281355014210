import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import styles from './EventRegistration.module.css';

const EventRegStudentItem = ({
  register,
  control,
  index,
  remove,
  errors,
  setValue,
  schools
}) => {
  const schoolSelectOptions = schools.map(school => ({
    label: school.school_lookup_name,
    value: school.pk_school_record_id
  }));

  const phoneNumberFormatter = e => {
    let formattedInputValue = e.target.value;
    // if input value is falsy, clear input and move on
    if (!formattedInputValue) {
      setValue(e.target.name, '');
      return;
    }

    // clean the input for any non-digit values
    let phoneNumber = formattedInputValue.replaceAll(/[^\d]/gi, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) {
      setValue(e.target.name, phoneNumber);
    } else if (phoneNumberLength < 7) {
      if (phoneNumber[0] != 1) {
        setValue(
          e.target.name,
          `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
        );
      } else {
        setValue(
          e.target.name,
          `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`
        );
      }
    } else {
      setValue(
        e.target.name,
        `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`
      );
    }
  };

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        {index > 0 && (
          <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
            <Button
              size="sm"
              variant="link"
              className="p-0"
              onClick={() => {
                remove(index);
              }}
            >
              <FontAwesomeIcon className="text-danger" icon="times-circle" />
            </Button>
          </div>
        )}

        <Row className="gx-3 gb-2">
          <h6 className="fs-0">Student Information</h6>
          <Col lg="4" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="first_name" label="First Name">
              <Form.Control
                type="text"
                placeholder="First Name"
                isInvalid={errors?.students?.[index]?.first_name}
                {...register(`students.${index}.first_name`, {
                  required: true,
                  minLength: 2,
                  pattern: {
                    value: /[A-zÀ-ú]{2,}/i,
                    message: 'Please enter a valid first name.'
                  }
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg="4" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="last_name" label="Last Name">
              <Form.Control
                type="text"
                placeholder="Last Name"
                isInvalid={errors?.students?.[index]?.last_name}
                {...register(`students.${index}.last_name`, {
                  required: true,
                  minLength: 2,
                  pattern: {
                    value: /[A-zÀ-ú]{2,}/i,
                    message: 'Please enter a valid last name.'
                  }
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg="4" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="gender" label="Select student gender">
              <Form.Select
                aria-label="Select student gender"
                defaultValue=""
                isInvalid={errors?.students?.[index]?.gender}
                {...register(`students.${index}.gender`, {
                  required: true
                })}
              >
                <option value="" disabled>
                  Please select one
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Transgender">Transgender</option>
                <option value="Non-binary/non-conforming">
                  Non-binary/non-conforming
                </option>
                <option value="Prefer not to respond">
                  Prefer not to respond
                </option>
              </Form.Select>
            </FloatingLabel>
          </Col>

          <h6 className="fs-0 mt-3">School Information</h6>
          <Col lg="8" className="mt-3 mt-lg-0">
            <Controller
              name={`students.${index}.school`}
              control={control}
              defaultValue={''} // Set a default value if needed
              rules={{ required: 'School selection is required' }} // Validation rules
              render={({ field, fieldState: { error } }) => (
                <div
                  className={
                    error
                      ? `${styles['react-select-container']} ${styles['react-select-error']}`
                      : styles['react-select-container']
                  }
                >
                  <Select
                    {...field}
                    options={schoolSelectOptions}
                    isClearable
                    isSearchable
                    placeholder="Select School"
                    styles={{ borderColor: 'red' }}
                    // If you need a custom onChange handler, you can use it like this:
                    // onChange={(selected) => {
                    //   field.onChange(selected ? selected.value : '');
                    // }}
                  />
                </div>
              )}
            />
            {errors?.students?.[index]?.school && (
              <div className="text-danger">
                {errors.students[index].school.message}
              </div>
            )}
            {/* <Form.Control
                type="text"
                placeholder="School"
                isInvalid={errors?.students?.[index]?.school}
                {...register(`students.${index}.school`, {
                  required: true,
                  minLength: 3,
                  pattern: {
                    value: /[A-zÀ-ú]{2,}/i,
                    message: 'Please enter a valid school name.'
                  }
                })}
              /> */}
          </Col>

          <Col lg="4">
            <FloatingLabel controlId="grade" label="School grade">
              <Form.Control
                type="number"
                placeholder="School grade"
                isInvalid={errors?.students?.[index]?.grade}
                {...register(`students.${index}.grade`, {
                  required: true,
                  min: { value: 4, message: 'Minimum 4th grade.' },
                  max: { value: 12, message: 'Maximum 12th grade.' },
                  pattern: /[0-9]{1,2}/i,
                  valueAsNumber: true,
                  validate: {
                    minimum: value => value >= 4 || 'Minimum 4th grade.',
                    maximum: value => value <= 12 || 'Maximum 12th grade.'
                  }
                })}
              />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">
              {errors?.students?.[index]?.grade?.message}
            </Form.Control.Feedback>
          </Col>

          <h6 className="fs-0 mt-3">Emegency Contact Information</h6>
          <Col lg="3" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="emergency_first_name" label="First Name">
              <Form.Control
                type="text"
                placeholder="First Name"
                isInvalid={errors?.students?.[index]?.emergency_first_name}
                {...register(`students.${index}.emergency_first_name`, {
                  required: true,
                  minLength: 2,
                  pattern: {
                    value: /[A-zÀ-ú]{2,}/i,
                    message: 'Please enter a valid first name.'
                  }
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg="3" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="emergency_last_name" label="Last Name">
              <Form.Control
                type="text"
                placeholder="Last Name"
                isInvalid={errors?.students?.[index]?.emergency_last_name}
                {...register(`students.${index}.emergency_last_name`, {
                  required: true,
                  minLength: 2,
                  pattern: {
                    value: /[A-zÀ-ú]{2,}/i,
                    message: 'Please enter a valid last name.'
                  }
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg="3" className="mb-2 mb-lg-0">
            <FloatingLabel
              controlId="emergency_phone_number"
              label="Phone Number"
            >
              <Form.Control
                type="tel"
                placeholder="Phone Number"
                isInvalid={errors?.students?.[index]?.emergency_phone_number}
                {...register(`students.${index}.emergency_phone_number`, {
                  required: true,
                  onChange: phoneNumberFormatter
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg="3" className="mb-2 mb-lg-0">
            <FloatingLabel controlId="emergency_email" label="Email Address">
              <Form.Control
                type="text"
                placeholder="Email Address"
                {...register(`students.${index}.emergency_email`)}
              />
            </FloatingLabel>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EventRegStudentItem;

EventRegStudentItem.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func,
  errors: PropTypes.object,
  schools: PropTypes.array
};
