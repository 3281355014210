import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { login } from 'helpers/api/auth.api.helper';
import { useForm } from 'react-hook-form';
import ButtonSpinner from 'components/utilities/ButtonSpinner';
import * as EmailValidator from 'email-validator';

const LoginForm = ({ hasLabel, layout, setShowLoginModal }) => {
  console.log('This is dum 1');
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location?.state?.from?.pathname.startsWith('/auth') && '/') ||
    location?.state?.from?.pathname ||
    '/';
  // const from = location.state?.from?.pathname.startsWith('/auth/')
  //   ? '/'
  //   : location.state?.from?.pathname || location?.pathname || '/';
  // console.log('Login form was rendered from', from);
  console.log('Location is', location);

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setFocus,
    formState: { isValid, isSubmitting }
  } = useForm({ mode: 'all' });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit = async loginData => {
    try {
      await login(loginData.email, loginData.password);
      reset();
      layout === 'modal' && setShowLoginModal(false);
      navigate(from, { replace: true });
    } catch (err) {
      resetField('password');
      setTimeout(() => {
        setFocus('password'), 50;
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          name="email"
          type="email"
          disabled={isSubmitting}
          {...register('email', {
            required: true,
            validate: value => EmailValidator.validate(value)
          })}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          name="password"
          type="password"
          disabled={isSubmitting}
          {...register('password', { required: 'true', minLength: 4 })}
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/auth/forgot-password">
            Forgot Your Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!isValid || isSubmitting}
        >
          <ButtonSpinner
            buttonText="Log in"
            ariaText="Logging in..."
            busy={isSubmitting}
          />
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  layout: PropTypes.string,
  setShowLoginModal: PropTypes.func
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
