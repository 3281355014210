import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row, Spinner } from 'react-bootstrap';

const EventRegFooter = ({ isSubmitting, isValidating, isValid, errors }) => {
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {isValidating &&
                'Little minions are checking the form for errors...'}
              {isValid
                ? 'Everything looks good!'
                : Object.keys(errors).length > 0 &&
                  'Please check for errors before submitting team registration.'}
            </h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-primary"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                >
                  <span className="visually-hidden">
                    Registering project...
                  </span>
                </Spinner>
              ) : (
                'Register Team'
              )}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventRegFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  isValidating: PropTypes.bool,
  isValid: PropTypes.bool,
  errors: PropTypes.object
};
export default EventRegFooter;
