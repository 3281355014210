import React, { useState, useMemo } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTeamsContext } from 'context/TeamsContext';
import { useLocation } from 'react-router-dom';

const TeamLookup = () => {
  const eventID = 'recOemN51VZz1zrMS';
  const { teams, students, loading, updateTeam, updateStudent } =
    useTeamsContext();
  const [searchValue, setSearchValue] = useState('');
  const [searchOption, setSearchOption] = useState('email');

  const location = useLocation(); // Use the useLocation hook
  const queryParams = new URLSearchParams(location.search);
  const isAdmin = queryParams.get('admin') === 'true'; // Check if admin=true is in the URL
  const canEdit =
    (searchOption === 'email' && searchValue.length > 4) || isAdmin; // Check if the search option is email or if the user is an admin

  // State for editing
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [editingStudentId, setEditingStudentId] = useState(null);
  const [editedTeamName, setEditedTeamName] = useState('');
  const [editedFirstName, setEditedFirstName] = useState('');
  const [editedLastName, setEditedLastName] = useState('');

  // Handler to update search option
  const handleSearchOptionChange = (event, newOption) => {
    if (newOption) {
      setSearchOption(newOption);
      setSearchValue('');
    }
  };

  // Filter teams based on eventID, search input, and selected search option
  const filteredTeams = useMemo(() => {
    return teams.filter(
      team =>
        team.fk_ext_registered_event_id?.includes(eventID) &&
        (searchOption === 'email'
          ? team.temp_team_leader_email
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            team.team_leader_email?.some(email =>
              email.toLowerCase().includes(searchValue.toLowerCase())
            )
          : team.rollup_team_members_name
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            team.lookup_table_name_ID?.some(name =>
              name.toLowerCase().includes(searchValue.toLowerCase())
            ))
    );
  }, [teams, searchValue, searchOption, eventID]);

  // Handler to update search input value
  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  // Function to find students by their ID in fk_team_members_id
  const getTeamMembers = memberIds => {
    return students.filter(student =>
      memberIds.includes(student.pk_student_record_id)
    );
  };

  // Handle editing logic for Team Name
  const handleEditTeamName = team => {
    setEditingTeamId(team.pk_team_record_id);
    setEditedTeamName(team.team_name);
  };

  const handleSaveTeamName = team => {
    if (editedTeamName !== team.team_name) {
      updateTeam(team.pk_team_record_id, { team_name: editedTeamName });
    }
    setEditingTeamId(null);
  };

  const handleCancelTeamName = () => {
    setEditingTeamId(null);
    setEditedTeamName('');
  };

  // Handle editing logic for Student Name
  const handleEditStudentName = student => {
    setEditingStudentId(student.pk_student_record_id);
    setEditedFirstName(student.first_name);
    setEditedLastName(student.last_name);
  };

  const handleSaveStudentName = student => {
    if (
      editedFirstName !== student.first_name ||
      editedLastName !== student.last_name
    ) {
      updateStudent(student.pk_student_record_id, {
        first_name: editedFirstName,
        last_name: editedLastName
      });
    }
    setEditingStudentId(null);
  };

  const handleCancelStudentName = () => {
    setEditingStudentId(null);
    setEditedFirstName('');
    setEditedLastName('');
  };

  const highlightMatch = name => {
    if (!searchValue) return name;

    const searchIndex = name.toLowerCase().indexOf(searchValue.toLowerCase());
    if (searchIndex === -1) return name;

    const beforeMatch = name.slice(0, searchIndex);
    const matchText = name.slice(searchIndex, searchIndex + searchValue.length);
    const afterMatch = name.slice(searchIndex + searchValue.length);

    return (
      <>
        {beforeMatch}
        <span style={{ backgroundColor: 'yellow' }}>{matchText}</span>
        {afterMatch}
      </>
    );
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        2024 Bridges Challenge <br />
        Team Check-In & Lookup
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Find your team by entering your email or student name.
      </Typography>

      {loading ? (
        <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Loading the 2024 Bridges Challenge Teams...
          </Typography>
          <CircularProgress />
        </Container>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <ToggleButtonGroup
              value={searchOption}
              exclusive
              onChange={handleSearchOptionChange}
              aria-label="search option"
            >
              <ToggleButton value="email" aria-label="search by email">
                Search by Registration Email
              </ToggleButton>
              <ToggleButton value="name" aria-label="search by student name">
                Search by Student Name
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ mt: 3 }}>
            <TextField
              label={
                searchOption === 'email'
                  ? 'Enter email address'
                  : 'Enter student name'
              }
              variant="outlined"
              fullWidth
              value={searchValue}
              onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={!searchValue}
          >
            Find My Team ({filteredTeams.length} result
            {filteredTeams.length !== 1 ? 's' : ''})
          </Button>
        </>
      )}

      {((searchOption === 'name' && searchValue.length > 2) ||
        (searchOption === 'email' && searchValue.length > 5)) && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Team Number</strong>
                </TableCell>
                <TableCell>
                  <strong>Team Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Student Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Grade</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTeams.map(team => {
                const teamMembers = getTeamMembers(team.fk_team_members_id);
                return (
                  <React.Fragment key={team.team_num}>
                    {teamMembers.map((member, index) => (
                      <TableRow key={member.pk_student_record_id}>
                        {index === 0 && (
                          <>
                            <TableCell rowSpan={teamMembers.length}>
                              {team.team_num}
                            </TableCell>
                            <TableCell rowSpan={teamMembers.length}>
                              {editingTeamId === team.pk_team_record_id ? (
                                <>
                                  <TextField
                                    value={editedTeamName}
                                    onChange={e =>
                                      setEditedTeamName(e.target.value)
                                    }
                                    variant="standard"
                                    fullWidth
                                  />
                                  <Box sx={{ mt: 1 }}>
                                    <Button
                                      onClick={() => handleSaveTeamName(team)}
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      sx={{ mr: 1 }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      onClick={handleCancelTeamName}
                                      color="secondary"
                                      variant="outlined"
                                      size="small"
                                    >
                                      Cancel
                                    </Button>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  {team.team_name}
                                  {canEdit && (
                                    <Tooltip title="Edit team name" arrow>
                                      <IconButton
                                        onClick={() => handleEditTeamName(team)}
                                        size="small"
                                      >
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          {editingStudentId === member.pk_student_record_id ? (
                            <>
                              <TextField
                                label="First Name"
                                value={editedFirstName}
                                onChange={e =>
                                  setEditedFirstName(e.target.value)
                                }
                                variant="standard"
                                fullWidth
                                sx={{ mb: 1 }}
                              />
                              <TextField
                                label="Last Name"
                                value={editedLastName}
                                onChange={e =>
                                  setEditedLastName(e.target.value)
                                }
                                variant="standard"
                                fullWidth
                              />
                              <Box sx={{ mt: 1 }}>
                                <Button
                                  onClick={() => handleSaveStudentName(member)}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  Save
                                </Button>
                                <Button
                                  onClick={handleCancelStudentName}
                                  color="secondary"
                                  variant="outlined"
                                  size="small"
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </>
                          ) : (
                            <>
                              {searchOption === 'name'
                                ? highlightMatch(member.full_name)
                                : member.full_name}
                              {canEdit && (
                                <Tooltip title="Edit student name" arrow>
                                  <IconButton
                                    onClick={() =>
                                      handleEditStudentName(member)
                                    }
                                    size="small"
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>{member.grade}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default TeamLookup;
