import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
// import Lottie from 'react-lottie';
import Lottie from 'react-lottie-player';
import animationData from './lottie/email-sent-successful.json';
import { AuthWizardContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const Success = ({ reset }) => {
  const { user, setStep, setUser } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
    navigate('/auth/login');
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="mx-auto pt-3">
              <Lottie
                loop
                animationData={animationData}
                play
                style={{ height: 150 }}
                // rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              />
            </div>
          </div>
          <h4 className="mb-1">Please check your email!</h4>
          <p className="fs-0">
            An email has been sent to <strong>{user.email}</strong>. Please
            click on the included link to confirm your email.
          </p>
          <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
            Return to Login
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
