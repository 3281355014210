import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import ButtonSpinner from 'components/utilities/ButtonSpinner';

const DeleteStudentModal = ({
  showModal,
  setShowModal,
  removeTeamMember,
  ...props
}) => {
  // Deleting state
  const [deleting, setDeleting] = useState(false);
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs-1">
          Confirm Student Deletion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to remove {props?.teamMember?.full_name} from
          this team? This action cannot be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="danger"
          disabled={deleting}
          onClick={() => {
            setDeleting(true);
            setTimeout(() => {
              setShowModal(false);
              setTimeout(() => {
                removeTeamMember(props.student_record_id);
              }, 50);
            }, 1000);

            // setTimeout(() => {
            //   setShowModal(false);
            //   setTimeout(() => {
            //     setDeleting(false);
            //   }, 500);
            // }, 1000);
          }}
        >
          <ButtonSpinner
            buttonText="Remove student"
            ariaText="Removing student..."
            busy={deleting}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteStudentModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  teamMember: PropTypes.object,
  removeTeamMember: PropTypes.func,
  student_record_id: PropTypes.string
};

export default DeleteStudentModal;
