import API from 'configs/api';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

const cookies = new Cookies();

export const login = async (email, password) => {
  try {
    const res = await API.post(
      '/auth/login',
      JSON.stringify({ email, password })
    );
    const { success, data } = res.data;
    if (success) {
      console.log('login server data', data);
      cookies.set('user', JSON.stringify(data), {
        path: '/',
        maxAge: data.user_group == 'data_entry' ? 6 * 60 * 60 : 59 * 60
      });
      toast.success(`Logged in as ${email}`, {
        position: 'top-center'
      });
      return success;
    }
  } catch (err) {
    if (err.response) {
      toast.error('Invalid username or password. Please try again.', {
        position: 'top-center'
      });
    } else {
      console.error(err);
      toast.error(
        'There was an issue connecting to the authentication server. Please try again later.',
        { position: 'top-center' }
      );
    }
    throw err;
  }
};

export const logout = () => {
  cookies.remove('user');
};

export const sendPasswordReset = async email => {
  try {
    await API.post('/auth/resetpassword', JSON.stringify({ email }));
    toast.success(`An email is sent to ${email} with password reset link`);
  } catch (err) {
    if (err.response) {
      console.log(err.response.status);
      console.log(err.response.data);
    } else {
      console.error(err);
    }
  }
};

export const getUserID = () => {
  return cookies.get('user').id;
};

export const getUserName = () => {
  return cookies.get('user').first_name;
};

export const getUserRole = () => {
  return cookies.get('user').user_group;
};

export const getUserEmail = () => {
  return cookies.get('user').email;
};

export const getUser = () => {
  return cookies.get('user');
};

export const isLoggedIn = () => {
  return cookies.get('user') && true;
};
