import API from 'configs/api';

/**
 *
 * @param {*} table_name
 * @param {*} record_id
 * @returns
 */
export const getRecordData = async (table_name, record_id) => {
  return API.get(`/${table_name}/${record_id}`);
};

export const getRecordsData_with_query = async (table_name, query) => {
  return API.get(`/${table_name}`, { params: query });
};

export const updateRecordData = async (table_name, record_id, data) => {
  return API.put(`/${table_name}/${record_id}`, data);
};

export const createNewRecord = async (table_name, data) => {
  return API.post(`/${table_name}`, data);
};

export const deleteRecord = async (table_name, record_id) => {
  return API.delete(`/${table_name}/${record_id}`);
};
