import React, { useState, useEffect } from 'react';
import {
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';
// import Overview from './Overview';
// import Scoreboard from './Scoreboard';
// import Teams from './Teams';
import Awards from './Awards';

import {
  // getBridgeJuniorScores,
  // getBridgeSeniorScores,
  getRoboticsJuniorScores,
  getRoboticsSeniorScores
} from 'helpers/api/dataentry.api.helper';

import API from 'configs/api';
import RoboticsScoreboard from 'components/scoreboard/RoboticsScoreboard';

export default function GameDay() {
  // State controls for dropdown menu
  const [selectedOption, setSelectedOption] = useState('Scoreboard');
  const handleDropdownChange = event => {
    setSelectedOption(event.target.value);
  };

  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const [juniorScores, setJuniorScores] = useState([]);
  const [seniorScores, setSeniorScores] = useState([]);
  const [event, setEvent] = useState({});

  const fetchScores = async () => {
    // Retrieve Junior Scores
    try {
      const jr_score_data = await getRoboticsJuniorScores();
      console.log('jr_score_data', jr_score_data.data);
      setJuniorScores(jr_score_data.data.records_data);
    } catch (error) {
      console.error(error);
    }

    try {
      const sr_score_data = await getRoboticsSeniorScores();
      console.log('sr_score_data', sr_score_data.data);
      setSeniorScores(sr_score_data.data.records_data);
    } catch (error) {
      console.error(error);
    }

    setLastUpdated(new Date());
  };

  useEffect(() => {
    const retrieveScoreboard = async () => {
      setLoading(true);
      fetchScores();
      setLoading(false);
    };

    const get_current_event = async () => {
      try {
        const { data } = await API.get('/events/current_event');
        // look at data.publish_scores, if true, setdisplayCurrentEventScoreboard to true, else false. If it doesn't exist/undefined, set to false
        console.log('Current event isssa:', data);
        console.log('Publish scores is:', data.publish_scores);
        setEvent(data);
        // setDisplayCurrentEventScoreboard(data.publish_scores);
      } catch (error) {
        console.error(error);
      }
    };

    retrieveScoreboard();
    get_current_event();
  }, []);

  const renderComponent = () => {
    switch (selectedOption) {
      //   case 'Overview':
      //     return <Overview />;
      case 'Awards':
        return (
          <Awards
            juniorTopScores={juniorScores.slice(0, 4)}
            seniorTopScores={seniorScores.slice(0, 4)}
            event={event}
          />
        );
      case 'Scoreboard':
        return !loading && <RoboticsScoreboard />;
      //   case 'Teams':
      //     return <Teams />;
      default:
        return <RoboticsScoreboard />;
    }
  };

  useEffect(() => {
    fetchScores(); // Fetch data immediately when the component mounts

    const interval = setInterval(fetchScores, 120000); // 2 minutes = 120000 milliseconds

    return () => clearInterval(interval); // Clear interval when the component is unmounted
  }, []);

  return (
    <Container
      sx={{
        paddingX: 4,
        paddingY: 2
      }}
    >
      <FormControl fullWidth variant="outlined" margin="normal" sx={{ mb: 2 }}>
        <InputLabel>View</InputLabel>
        <Select
          value={selectedOption}
          onChange={handleDropdownChange}
          label="Options"
        >
          {/* <MenuItem value="Overview">Overview</MenuItem> */}
          <MenuItem value="Awards">Award Ceremony</MenuItem>
          <MenuItem value="Scoreboard">Scoreboard</MenuItem>
          {/* <MenuItem value="Teams">Teams</MenuItem> */}
        </Select>
      </FormControl>
      <Typography
        variant="body1"
        fontSize={'1.5rem'}
        align="center"
        style={{ marginTop: '16px' }}
      >
        Last updated: {lastUpdated.toLocaleTimeString()}
      </Typography>

      {renderComponent()}
    </Container>
  );
}
