// Importing libraries and dependencies
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import is from 'is_js';

// Importing Routes
import AuthRoutes from 'routes/authentication/AuthRoutes';
// import EventsRoutes from 'routes/events/EventsRoutes';

// import Landing from 'components/pages/landing/Landing';

import ErrorLayout from 'layouts/ErrorLayout';
import { ToastContainer } from 'react-toastify';
import MainLayout from 'layouts/MainLayout';
// import SupportRoutes from 'routes/support';
// import GameDay from 'components/pages/gameday/GameDay';
import TeamLookup from 'pages/checkin/TeamLookup';
import BridgeScoreboard from 'components/scoreboard/BridgeScoreboard';
import BridgesGameDay from 'components/pages/gameday/BridgesGameDay';
import GameDay from 'components/pages/gameday/GameDay';

const DataEntryRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<BridgeScoreboard />}></Route>
        <Route path="/*" element={<MainLayout />}></Route>
        <Route
          path="/data-entry"
          element={
            <DataEntryRedirect to="https://airtable.com/appJmvN5BaVRo26NH/pagjAKPUOKzuSjRsn/form" />
          }
        ></Route>
        <Route path="/gameday" element={<BridgesGameDay />} />
        <Route path="/oldgameday" element={<GameDay />} />
        <Route path="/checkin" element={<TeamLookup />} />
        {/* <Route path="/support/*" element={<SupportRoutes />}></Route> */}
        <Route path="/auth/*" element={<AuthRoutes />}></Route>
        <Route path="*" element={<Navigate to="/errors/404" />} />
        <Route path="/errors/*" element={<ErrorLayout />}></Route>
      </Routes>
      <ToastContainer position={'bottom-left'} theme={'colored'} />
    </Router>
  );
};

export default App;
