import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

const EventsList = ({ events }) => {
  // Loop through each event and display it
  return (
    <div>
      {events.records_data.map(event => (
        <Row key={event.PK_event_record_ID} className="mb-3">
          <Flex>
            <Calendar
              month={event.event_date_month}
              day={event.event_date_day}
            />
            <h6 className="fs-0 ms-3">
              <Link to={`/event/${event.PK_event_record_ID}`}>
                {event.event_name}
              </Link>
            </h6>
          </Flex>
        </Row>
      ))}
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.object.isRequired
};

export default EventsList;
