import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
// import RegistrationForm from 'components/authentication/RegistrationForm';
import LoginForm from 'layouts/authentication/LoginForm';

const LoginModal = ({ showLoginModal, setShowLoginModal }) => {
  return (
    <Modal
      show={showLoginModal}
      onHide={() => {
        setShowLoginModal(false);
      }}
      className="mt-4"
    >
      <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
        <div className="position-relative z-index-1 light">
          <h4 className="mb-0 text-white" id="authentication-modal-label">
            Login
          </h4>
          <p className="fs--1 mb-0 text-white">
            Log in to access your MOST STEM Challenge account.
          </p>
        </div>
        <CloseButton
          variant="white"
          className="position-absolute end-0 me-2 mt-2 top-0"
          onClick={() => {
            setShowLoginModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        <LoginForm
          hasLabel
          layout="modal"
          setShowLoginModal={setShowLoginModal}
        />
      </Modal.Body>
    </Modal>
  );
};

LoginModal.propTypes = {
  showLoginModal: PropTypes.bool,
  setShowLoginModal: PropTypes.func
};

export default LoginModal;
