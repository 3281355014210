import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Importing different auth page layouts
import Login from 'layouts/authentication/Login';
import Logout from 'layouts/authentication/Logout';
import Registration from 'layouts/authentication/Registration';
import ForgotPassword from 'layouts/authentication/ForgotPassword';
import ConfirmMail from 'layouts/authentication/ConfirmMail';
import PasswordReset from 'layouts/authentication/PasswordReset';
import { RequireAuth, UnauthOnly } from 'routes/AuthCheck';
// import LockScreen from './LockScreen';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/errors/404" />} />
      <Route
        path="login"
        element={
          <UnauthOnly>
            <Login />
          </UnauthOnly>
        }
      ></Route>
      <Route
        path="register"
        element={
          <UnauthOnly>
            <Registration />
          </UnauthOnly>
        }
      ></Route>
      <Route
        path="logout"
        element={
          <RequireAuth>
            <Logout />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="forgot-password"
        element={
          <UnauthOnly>
            <ForgotPassword />
          </UnauthOnly>
        }
      ></Route>
      <Route path="reset-password/:token" element={<PasswordReset />}></Route>
      <Route
        path="verify/:token"
        element={
          <UnauthOnly>
            <ConfirmMail />
          </UnauthOnly>
        }
      ></Route>
    </Routes>
  );
};

export default AuthRoutes;
