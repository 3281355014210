import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
const EventRegTeamInfo = ({ register, errors }) => {
  //   const [formData, setFormData] = useState({
  //     startDate: null,
  //     endDate: null,
  //     regDate: null,
  //     startTime: null,
  //     endTime: null
  //   });
  return (
    <Card className="mb-3">
      <Card.Header as="h5">Team Information</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-3 gy-3">
          <Form.Group as={Row} className="my-2 mt-3" controlId="team_name">
            <Form.Label column md="3">
              Team Name
            </Form.Label>
            <Col md="9">
              <Form.Control
                type="text"
                name="team_name"
                placeholder="Team Name (optional)"
                isInvalid={errors['team_name']}
                {...register('team_name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors['team_name']?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventRegTeamInfo.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};
export default EventRegTeamInfo;
