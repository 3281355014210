import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
// import { Redirect, Route, Switch } from 'react-router';
import { Routes, Route, Navigate } from 'react-router-dom';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Section from 'components/common/Section';

const ErrorLayout = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo width={200} />
          <Routes>
            <Route path="*" element={<Navigate to="/errors/404" />} />
            <Route path="404" element={<Error404 />} />
            <Route path="500" element={<Error500 />} />
          </Routes>
        </Col>
      </Row>
    </Section>
  );
};

ErrorLayout.propTypes = {
  match: PropTypes.object
};

export default ErrorLayout;
