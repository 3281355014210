import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn, getUserRole } from 'helpers/api/auth.api.helper';

export const RequireAuth = props => {
  const location = useLocation();

  if (!isLoggedIn())
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  return <>{props.children}</>;
};

export const UnauthOnly = props => {
  if (isLoggedIn()) return <Navigate to="/" replace />;
  return <>{props.children}</>;
};

export const DataEntryOnly = props => {
  const location = useLocation();

  if (!isLoggedIn())
    return <Navigate to="/auth/login" state={{ from: location }} replace />;

  const userRole = getUserRole();
  if (!(userRole === 'admin' || userRole === 'data_entry'))
    return <Navigate to="/" replace />;
  return <>{props.children}</>;
};

RequireAuth.propTypes = { children: PropTypes.node };
UnauthOnly.propTypes = { children: PropTypes.node };
DataEntryOnly.propTypes = { children: PropTypes.node };
