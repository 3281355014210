import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import EventRegStudentItem from './EventRegStudentItem';

const EventRegStudent = ({
  fields,
  register,
  append,
  remove,
  control,
  setValue,
  defaultStudentValues,
  errors,
  schools
}) => {
  return (
    <Card className="mb-3">
      <Card.Header as="h5">Team Members</Card.Header>
      <Card.Body className="bg-light">
        {fields.map((student, index) => (
          <EventRegStudentItem
            key={student.id}
            control={control}
            register={register}
            setValue={setValue}
            index={index}
            remove={remove}
            errors={errors}
            schools={schools}
          />
        ))}

        <IconButton
          // onClick={addStudentToTeamHandler}
          onClick={() => {
            append(defaultStudentValues);
          }}
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
        >
          Add Another Student
        </IconButton>
      </Card.Body>
    </Card>
  );
};

EventRegStudent.propTypes = {
  fields: PropTypes.array,
  setValue: PropTypes.func,
  control: PropTypes.object,
  defaultStudentValues: PropTypes.object,
  register: PropTypes.func,
  append: PropTypes.func,
  remove: PropTypes.func,
  errors: PropTypes.object,
  schools: PropTypes.array
};

export default EventRegStudent;
