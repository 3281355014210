import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { createNewRecord, updateRecordData } from 'helpers/api/api.helper';
import { TeamMemberFormInput } from '../team-detail-form/TeamMemberFormInput';
import * as EmailValidator from 'email-validator';

export const TeamMemberEditModal = ({
  submitType,
  team_record_id,
  teamMember,
  student_record_id,
  showEditModal,
  setShowEditModal,
  setTeamMembers
}) => {
  // Set up states
  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  // Set up react-hook-form
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, touchedFields },
    setValue,
    reset
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: teamMember
  });

  console.log('is form submitting? ', isSubmitting);

  // Phone number formatter
  const phoneNumberFormatter = e => {
    let formattedInputValue = e.target.value;
    // If input value is falsy, return empty string
    if (!formattedInputValue) {
      setValue('emergency_phone_number', '');
      return;
    }

    // clean the input for any non-digit characters
    formattedInputValue = formattedInputValue.replaceAll(/\D/g, '');
    const phoneNumberLength = formattedInputValue.length;

    if (phoneNumberLength < 4) {
      setValue('emergency_phone_number', formattedInputValue);
      return;
    } else if (phoneNumberLength < 7) {
      setValue(
        'emergency_phone_number',
        `(${formattedInputValue.slice(0, 3)}) ${formattedInputValue.slice(3)}`
      );
      return;
    } else {
      setValue(
        'emergency_phone_number',
        `(${formattedInputValue.slice(0, 3)}) ${formattedInputValue.slice(
          3,
          6
        )}-${formattedInputValue.slice(6, 10)}`
      );
      return;
    }
  };

  // Handle form submission
  const onSubmit = async form_data => {
    setSubmitSuccessful(false);
    console.log(form_data);
    const {
      first_name,
      last_name,
      gender,
      grade,
      school,
      emergency_first_name,
      emergency_last_name,
      emergency_phone_number,
      emergency_email
    } = form_data;
    const submit_form_data = {
      first_name,
      last_name,
      gender,
      grade,
      school,
      emergency_first_name,
      emergency_last_name,
      emergency_phone_number,
      emergency_email,
      fk_team: teamMember?.fk_team || [team_record_id]
    };
    console.log('submit form data', submit_form_data);

    try {
      // If submitType is 'add', create new student record
      // If submitType is 'edit', update existing student record
      let result;
      if (submitType === 'add') {
        result = await createNewRecord('student', submit_form_data);
      } else if (submitType === 'edit') {
        result = await updateRecordData(
          'students',
          student_record_id,
          submit_form_data
        );
      }
      const { record_id, record_data } = result.data;

      setSubmitSuccessful(true);
      setTeamMembers(prevTeamMembers => {
        return { ...prevTeamMembers, [record_id]: record_data };
      });

      // set timeout before closing modal
      setTimeout(() => {
        // reset();
        setShowEditModal(false);
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const displayModalTitle = () => {
    switch (submitType) {
      case 'edit':
        return 'Edit Team Member';
      case 'add':
        return 'Add Team Member';
      default:
        return 'Edit Team Member';
    }
  };

  return (
    <Modal
      size="lg"
      show={showEditModal}
      onHide={() => setShowEditModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>{`${teamMember?.first_name} ${teamMember?.last_name} Details`}</Modal.Title> */}
        <Modal.Title>{displayModalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <h6 className="fs-0 mb-3">Personal Information</h6>
          <TeamMemberFormInput
            type="text"
            label="First Name"
            name="first_name"
            placeholder="Student's first name"
            formGroupProps={{ controlId: 'formFirstName' }}
            formControlProps={{
              ...register('first_name', {
                required: "The student's first name is required.",
                pattern: {
                  value: /[A-zÀ-ú]{2,}/i,
                  message: 'Please enter a valid first name.'
                }
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="text"
            label="Last Name"
            name="last_name"
            placeholder="Student's last name"
            formGroupProps={{ controlId: 'formLastName' }}
            formControlProps={{
              ...register('last_name', {
                required: "The student's last name is required.",
                pattern: {
                  value: /[A-zÀ-ú]{2,}/i,
                  message: 'Please enter a valid last name.'
                }
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="select"
            label="Gender"
            name="gender"
            placeholder="Select student gender"
            touchedFields={touchedFields}
            errors={errors}
            formGroupProps={{ controlId: 'formGender' }}
            formControlProps={{
              ...register('gender', {
                required: "The student's gender is required."
              })
            }}
            options={[
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
              { label: 'Transgender', value: 'Transgender' },
              {
                label: 'Non-binary/non-conforming',
                value: 'Non-binary/non-conforming'
              },
              { label: 'Prefer not to respond', value: 'Prefer not to respond' }
            ]}
          />

          <h6 className="fs-0 mb-3">School Information</h6>

          <TeamMemberFormInput
            type="number"
            label="Grade"
            name="grade"
            placeholder="School grade between 4 and 12"
            formGroupProps={{ controlId: 'formSchoolGrade' }}
            formControlProps={{
              ...register('grade', {
                required: 'Student grade is required.',
                min: { value: 4, message: 'Minimum 4th grade.' },
                max: { value: 12, message: 'Maximum 12th grade.' },
                pattern: /[0-9]{1,2}/i,
                valueAsNumber: true,
                validate: {
                  minimum: value => value >= 4 || 'Minimum 4th grade.',
                  maximum: value => value <= 12 || 'Maximum 12th grade.'
                }
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="text"
            label="School Name"
            name="school"
            placeholder="School name name"
            formGroupProps={{ controlId: 'formSchoolName' }}
            formControlProps={{
              ...register('school', {
                required:
                  "The student's school name is required — enter 'Homeschool' if homeschooled."
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <h6 className="fs-0 mb-3">Emergency Contact Information</h6>
          <TeamMemberFormInput
            type="text"
            label="First Name"
            name="emergency_first_name"
            placeholder="Emergency contact first name"
            formGroupProps={{ controlId: 'formEmergencyFirstName' }}
            formControlProps={{
              ...register('emergency_first_name', {
                required: "Emergency contact's first name is required.",
                pattern: {
                  value: /[A-zÀ-ú]{2,}/i,
                  message: 'Please enter a valid first name.'
                }
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="text"
            label="Last Name"
            name="emergency_last_name"
            placeholder="Emergency contact last name"
            formGroupProps={{ controlId: 'formEmergencyLastname' }}
            formControlProps={{
              ...register('emergency_last_name', {
                required: "Emergency contact's last name is required.",
                pattern: {
                  value: /[A-zÀ-ú]{2,}/i,
                  message: 'Please enter a valid last name.'
                }
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="tel"
            label="Phone Number"
            name="emergency_phone_number"
            placeholder="(315) 123-4567"
            formGroupProps={{ controlId: 'formEmergencyPhoneNumber' }}
            formControlProps={{
              ...register('emergency_phone_number', {
                required: 'Emergency contact phone number is required.',
                minLength: 14,
                onChange: phoneNumberFormatter
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
          <TeamMemberFormInput
            type="email"
            label="Email address"
            name="emergency_email"
            placeholder="Emergency Contact Email"
            formGroupProps={{ controlId: 'formEmergencyEmail' }}
            formControlProps={{
              ...register('emergency_email', {
                required: "Emergency contact's email is required.",
                validate: value =>
                  EmailValidator.validate(value) ||
                  'Please enter a valid email address.'
              })
            }}
            touchedFields={touchedFields}
            errors={errors}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            setShowEditModal(false);
            reset();
          }}
          disabled={isSubmitting || submitSuccessful}
        >
          Close without saving
        </Button>
        <Button
          variant={submitSuccessful ? 'success' : 'primary  '}
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || submitSuccessful}
        >
          {isSubmitting && !submitSuccessful
            ? submitType === 'add'
              ? 'Adding new student...'
              : 'Saving changes...'
            : submitSuccessful
            ? submitType === 'add'
              ? 'Student added!'
              : 'Changes saved.'
            : 'Save changes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Team Member Edit View PropType Validation
TeamMemberEditModal.propTypes = {
  submitType: PropTypes.string.isRequired,
  team_record_id: PropTypes.string,
  student_record_id: PropTypes.string,
  teamMember: PropTypes.object,
  showEditModal: PropTypes.bool.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  setTeamMembers: PropTypes.func.isRequired
};
