import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

const ButtonSpinner = ({ buttonText, ariaText, busy }) => {
  return busy ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    >
      <span className="visually-hidden">{ariaText}</span>
    </Spinner>
  ) : (
    buttonText
  );
};

ButtonSpinner.propTypes = {
  buttonText: PropTypes.string,
  ariaText: PropTypes.string,
  busy: PropTypes.bool
};

export default ButtonSpinner;
