import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import animationData from './lottie/register-success.json';
import { useNavigate } from 'react-router-dom';

const EventRegSuccess = ({ reset, event_record_id, event }) => {
  const navigate = useNavigate();
  // Preload event name with "the" appended in front of the event name
  const getEventName = () => {
    if (event?.record_data?.event_name) {
      return `the ${event?.record_data?.event_name}`;
    } else {
      return 'the event';
    }
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="mx-auto pt-3">
                <Lottie
                  loop
                  animationData={animationData}
                  play
                  style={{ height: 150 }}
                  // rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                />
              </div>
            </div>
            <h4 className="mb-1">
              Your team has been successfully registered for {getEventName()}!
            </h4>
            <p className="fs-0">
              An event registration confirmation has been sent to your email on
              file. Please check your email for additional event information.
            </p>

            <Button
              variant="falcon-default"
              className="px-3 my-3 me-3"
              onClick={() => navigate(`/event/${event_record_id}`)}
            >
              View My Registered Teams
            </Button>
            <Button
              color="primary"
              className="px-3 my-3 me-3"
              onClick={() => reset()}
            >
              Register Another Team
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventRegSuccess.propTypes = {
  reset: PropTypes.func,
  event_record_id: PropTypes.string,
  event: PropTypes.object
};

export default EventRegSuccess;
