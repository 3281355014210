import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal } from 'react-bootstrap';

const TeamProjectInformation = ({ projectData }) => {
  console.log('Team Project Information');
  console.log('Project Data', projectData);
  const [docViewer, showDocViewer] = useState(false);
  const [viewFile, setViewFile] = useState({});

  return (
    <>
      <Modal
        size="xl"
        show={docViewer}
        onHide={() => {
          showDocViewer(false);
          setViewFile({});
        }}
        aria-labelledby="doc-viewer"
      >
        <Modal.Header closeButton>
          <Modal.Title id="doc-viewer">{viewFile.filename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={viewFile.url} width="100%" height="1000vh" />
        </Modal.Body>
      </Modal>

      <Row className="mb-2 mb-md-0">
        <Col md={3} xxl={2}>
          <p className="fw-semi-bold mb-1">Project Title</p>
        </Col>
        <Col>{projectData.project_title}</Col>
      </Row>
      <Row className="mb-2 mb-md-0">
        <Col md={3} xxl={2}>
          <p className="fw-semi-bold mb-1">Project Category</p>
        </Col>
        <Col>{projectData.project_category}</Col>
      </Row>
      {projectData?.team_category[0].includes('Senior') && (
        <Row className="mb-2 mb-md-0">
          <Col md={3} xxl={2}>
            <p className="fw-semi-bold mb-1">Project Abstract</p>
          </Col>
          <Col>
            {projectData.project_abstract || (
              <p className="text-danger">
                Please update your project details to include a project
                abstract.
              </p>
            )}
          </Col>
        </Row>
      )}
      {projectData?.team_category[0].includes('Senior') && (
        <Row className="mb-2 mb-md-0">
          <Col md={3} xxl={2}>
            <p className="fw-semi-bold mb-1">Approval Required Items</p>
          </Col>
          <Col>
            {projectData.restricted_humans && (
              <p className="mb-0">
                Humans (including student designed inventions/prototypes)
              </p>
            )}
            {projectData.restricted_animals && (
              <p className="mb-0">Vertebrate Animals</p>
            )}
            {projectData.restricted_biohazard && (
              <p className="mb-0">Potentially Hazardous Biological Agents</p>
            )}
            {projectData.restricted_hazard && (
              <p className="mb-0">
                Hazardous Chemicals, Activities, and Devices
              </p>
            )}
          </Col>
        </Row>
      )}
      {projectData?.team_category[0].includes('Senior') && (
        <Row className="my-3 mb-md-0">
          <Col md={3} xxl={2}>
            <p className="fw-semi-bold mb-1">Attached Forms</p>
          </Col>
          <Col>
            {(!projectData.attached_forms ||
              projectData.attached_forms?.length <
                projectData.min_req_forms) && (
              <p className="text-danger mb-0">
                You are missing at least{' '}
                {projectData.min_req_forms -
                  (projectData.attached_forms?.length || 0)}{' '}
                forms. You are required to upload your project abstract and any
                other required forms.
              </p>
            )}
            {projectData.attached_forms?.map(file => {
              return (
                <div key={file.id}>
                  <Button
                    variant="link"
                    className="px-0"
                    onClick={() => {
                      setViewFile(file);
                      showDocViewer(true);
                    }}
                  >
                    {file.filename}
                  </Button>
                </div>
              );
            })}
          </Col>
        </Row>
      )}
    </>
  );
};

TeamProjectInformation.propTypes = {
  projectData: PropTypes.object,
  setProjectData: PropTypes.func
};

export default TeamProjectInformation;
