import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Import hooks
import { useParams } from 'react-router-dom';
import { getRecordData } from 'helpers/api/api.helper';
import { toggleTeamWithdrawnStatus } from 'helpers/api/teams.api.helper';

// Import subcomponents
import { Button } from 'react-bootstrap';
import TeamDetailsHeader from './TeamDetailsHeader';
import TeamDetailCard from './TeamDetailCard';
import TeamDetailInfoBody from './TeamDetailInfoBody';
import TeamDetailMemberBody from './TeamDetailMemberBody';
import { TeamMemberEditModal } from './team-detail-member/TeamMemberEditModal';
import TeamProjectInformation from 'components/pages/teams/projects/TeamProjectInformation';
import ProjectStatusPill from 'components/pages/teams/projects/ProjectStatusPill';
import TeamProjectEdit from 'components/pages/teams/projects/TeamProjectEdit';

const TeamDetails = props => {
  const { team_record_id } = useParams();
  let team_rec_id = team_record_id || props.team_record_id; // will set team_rec_id to either params or props
  console.log('Team Details Page', team_rec_id);

  // Set page display states
  const [teamLoading, setTeamLoading] = useState(true); // loading team data state: true = loading, false = done loading
  const [teamMembersLoading, setTeamMembersLoading] = useState(true); // loading team members data state: true = loading, false = done loading
  const [projectLoading, setProjectLoading] = useState(true); // loading project data state: true = loading, false = done loading
  // const [kitLoading, setKitLoading] = useState(true); // loading kit data state: true = loading, false = done loading
  const [isEventAFair, setIsEventAFair] = useState(false); // is event a fair state: true = fair, false = not fair

  // Set edit card states
  const [showEditModal, setShowEditModal] = useState(false); // show edit modal state: true = show, false = hide
  const [editProjectInfo, setEditProjectInfo] = useState(false); // edit project info state: true = edit, false = view info

  // Submit references
  const editProjectRef = useRef();

  // Submit form states
  const [isSavingProjectInfo, setIsSavingProjectInfo] = useState(false); // is saving project info state: true = saving, false = not saving
  const [projectInfoValid, setProjectInfoValid] = useState(false); // project info valid state: true = valid, false = invalid

  // Set page data states
  const [team, setTeam] = useState({});
  const [teamMembers, setTeamMembers] = useState(null);
  const [project, setProject] = useState(null);
  // const [kit, setKit] = useState(null);

  // useEffect to retrieve team information from API server and set to team state
  useEffect(() => {
    const get_project_info = async project_rec_id => {
      try {
        const { data } = await getRecordData('cnysef_projects', project_rec_id);
        console.log('project', data);
        const { record_id, record_data } = data;
        setProject({ record_id, record_data });
        setProjectLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const get_student_info = async student_rec_id => {
      try {
        const { data } = await getRecordData('students', student_rec_id);
        const { record_id, record_data } = data;
        setTeamMembers(prevTeamMembers => {
          return { ...prevTeamMembers, [record_id]: record_data };
        });
      } catch (err) {
        console.error(err);
      }
    };

    const get_team_info = async () => {
      try {
        console.log('Fetching team data for team_rec_id', team_rec_id);
        setTeamLoading(true);
        const { data } = await getRecordData('teams', team_rec_id);
        const { record_id, record_data } = data;
        console.log(`Team ${team_rec_id} data:`, record_data);
        console.log('project id', record_data.FK_cnysef_project);
        setTeam({ record_id, record_data });
        setTeamLoading(false);
        // Check if event is a fair
        if (
          record_data.registered_event_type.includes('CNYSEF') ||
          record_data.registered_event_type.includes('NYS Science Congress')
        ) {
          setIsEventAFair(true);
          console.log('Event is a fair');
        }

        // get team members information from API server
        setTeamMembersLoading(true);
        const { fk_team_members_id } = record_data;
        console.log('fk_team_members_id', fk_team_members_id);
        // loop through fk_team_members_id array and get student info
        for await (const student_rec_id of fk_team_members_id) {
          await get_student_info(student_rec_id);
        }
        setTeamMembersLoading(false);

        // If event is a fair, get project information from API server
        if (isEventAFair && record_data.FK_cnysef_project) {
          const project_id = record_data.FK_cnysef_project;
          await get_project_info(project_id);
        }
      } catch (err) {
        console.error(err);
      }
    };
    get_team_info();
  }, [team_rec_id, isEventAFair]);

  return (
    <div>
      <TeamDetailsHeader
        team_name={team?.record_data?.team_name}
        withdrawn={team?.record_data?.withdrawn}
      />
      {/* Team Details: Team Name */}
      {!isEventAFair && (
        <TeamDetailCard
          className="mb-3"
          cardTitle="Team Information"
          section_name="Team Information"
          loading={teamLoading}
        >
          <TeamDetailInfoBody
            team_id={team?.record_id}
            team_name={team?.record_data?.team_name}
            setTeam={setTeam}
          />
        </TeamDetailCard>
      )}
      {/* Team Student List */}
      <TeamDetailCard
        className="mb-3"
        cardTitle="Team Members"
        no-padding={true}
        section_name="Team Members"
        loading={teamMembersLoading}
      >
        {showEditModal && (
          <TeamMemberEditModal
            submitType="add"
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            setTeamMembers={setTeamMembers}
            team_record_id={team?.record_id}
          />
        )}
        <TeamDetailMemberBody
          teamMembers={teamMembers}
          setTeamMembers={setTeamMembers}
          // removeTeamMember={removeTeamMember}
        />
        <div className="m-3 d-flex justify-content-center">
          <Button variant="primary" onClick={() => setShowEditModal(true)}>
            Add team member
          </Button>
        </div>
      </TeamDetailCard>
      {/* Project Detail for CNYSEF */}
      {isEventAFair && (
        <TeamDetailCard
          className="mb-3"
          cardTitle={
            editProjectInfo
              ? 'Editing Project Information'
              : 'Project Information'
          }
          section_name="Project Information"
          loading={projectLoading}
          pill={
            !editProjectInfo &&
            project?.record_data?.project_review_status && (
              <ProjectStatusPill
                project_review_status={
                  project?.record_data?.project_review_status
                }
              />
            )
          }
          edit_button_text={!editProjectInfo && 'Update project details'}
          editMode={editProjectInfo}
          setEditMode={setEditProjectInfo}
          saveRef={editProjectRef}
          isFormValid={projectInfoValid}
          isSubmitting={isSavingProjectInfo}
        >
          {project && !editProjectInfo && (
            <TeamProjectInformation
              team_category={team?.record_data?.team_category}
              projectData={project.record_data}
            />
          )}
          {project && editProjectInfo && (
            <TeamProjectEdit
              projectData={project.record_data}
              setProjectData={setProject}
              team_category={team?.record_data?.team_category}
              setEditMode={setEditProjectInfo}
              saveRef={editProjectRef}
              setSubmittingStatus={setIsSavingProjectInfo}
              isFormValid={setProjectInfoValid}
            />
          )}
        </TeamDetailCard>
      )}

      {/* Team Withdraw button */}
      <div className="mt-4 d-flex justify-content-center">
        <Button
          variant={
            team?.record_data?.withdrawn ? 'outline-success' : 'outline-danger'
          }
          onClick={() => {
            console.log('team info', team);
            // toggle team withdrawn status
            toggleTeamWithdrawnStatus(team.record_id);
            setTeam(prevTeam => {
              return {
                ...prevTeam,
                record_data: {
                  ...prevTeam.record_data,
                  withdrawn: !prevTeam.record_data.withdrawn
                }
              };
            });
          }}
        >
          {team?.record_data?.withdrawn ? 'Re-register' : 'Withdraw'} Team{' '}
          {team?.record_data?.withdrawn ? 'to' : 'from'} Event
        </Button>
      </div>
    </div>
  );
};

TeamDetails.propTypes = {
  team_record_id: PropTypes.string
};

export default TeamDetails;
