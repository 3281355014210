import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import ProjectStatusPill from 'components/pages/teams/projects/ProjectStatusPill';

const Actions = ({ team_record_id, toggleTeamWithdrawnStatus, withdrawn }) => (
  <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
    <Button
      variant="light"
      size="sm"
      className="border-300 me-1 text-600"
      as={Link}
      to={`/team/${team_record_id}`}
    >
      <FontAwesomeIcon icon="edit" />
    </Button>
    <Button
      variant="light"
      size="sm"
      className="border-300 text-600"
      onClick={() => toggleTeamWithdrawnStatus(team_record_id)}
    >
      {withdrawn ? (
        <FontAwesomeIcon icon="plus" />
      ) : (
        <FontAwesomeIcon icon="trash-alt" />
      )}
    </Button>
  </div>
);

const EventDetailTeamsList = ({
  teams,
  toggleTeamWithdrawnStatus,
  isEventAFair
}) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <Card.Title>
            {isEventAFair ? 'Projects' : 'Teams'} Registered
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0">
          <Table hover responsive>
            <thead>
              <tr>
                <th scope="col" style={{ width: '15%' }}>
                  {isEventAFair ? 'Project Number' : 'Team Number'}
                </th>
                <th scope="col">
                  {isEventAFair ? 'Project Title' : 'Team Name'}
                </th>
                <th scope="col">Team Members</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {teams?.map(team => {
                return (
                  <tr
                    key={team.pk_team_record_id}
                    className="hover-actions-trigger"
                  >
                    <td>
                      <Link
                        to={`/team/${team.pk_team_record_id}`}
                        className={classNames({
                          'text-decoration-line-through text-500':
                            team.withdrawn
                        })}
                      >
                        {team.team_num}
                      </Link>
                    </td>
                    <td>
                      <span
                        className={classNames({
                          'text-decoration-line-through text-500':
                            team.withdrawn
                        })}
                      >
                        {team.project_title || team.team_name || ''}
                        {isEventAFair && !team.withdrawn && (
                          <ProjectStatusPill
                            project_review_status={
                              team.cnysef_proj_reg_status[0]
                            }
                          />
                        )}
                      </span>

                      {team.withdrawn && (
                        <SoftBadge pill bg="danger" className="mx-2">
                          Withdrawn
                        </SoftBadge>
                      )}
                    </td>
                    <td>
                      <span
                        className={classNames({
                          'text-decoration-line-through text-500':
                            team.withdrawn
                        })}
                      >
                        {team.rollup_team_members_name || ''}
                      </span>
                    </td>
                    <td className="w-auto">
                      <Actions
                        team_record_id={team.pk_team_record_id}
                        toggleTeamWithdrawnStatus={toggleTeamWithdrawnStatus}
                        withdrawn={team.withdrawn}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

EventDetailTeamsList.propTypes = {
  teams: PropTypes.array,
  toggleTeamWithdrawnStatus: PropTypes.func,
  isEventAFair: PropTypes.bool
};

Actions.propTypes = {
  team_record_id: PropTypes.string,
  toggleTeamWithdrawnStatus: PropTypes.func,
  withdrawn: PropTypes.bool
};

export default EventDetailTeamsList;
