import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { deleteTeam } from 'helpers/api/teams.api.helper';
import ButtonSpinner from 'components/utilities/ButtonSpinner';

const DeleteConfirmationModal = ({
  showModal,
  setShowModal,
  deleteItem,
  setDeleteItem,
  queryTeamData
}) => {
  const [deleting, setDeleting] = useState(false);
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs-1">
          Confirm Team Deletion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete {deleteItem?.name}? This action cannot
          be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={deleting}
          onClick={() => {
            setShowModal(false);
            setDeleteItem(null);
          }}
        >
          Close
        </Button>
        <Button
          variant="danger"
          disabled={deleting}
          onClick={async () => {
            setDeleting(true);
            await deleteTeam(deleteItem?.recordID);
            await queryTeamData();
            setShowModal(false);
            setTimeout(() => {
              setDeleting(false);
            }, 1000);
            setDeleteItem(null);
          }}
        >
          <ButtonSpinner
            buttonText="Delete"
            ariaText="Deleting team..."
            busy={deleting}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteItem: PropTypes.object,
  setDeleteItem: PropTypes.func,
  queryTeamData: PropTypes.func
};

export default DeleteConfirmationModal;
