import React from 'react';
import {
  // Redirect,
  Route,
  Routes,
  Navigate
  // useLocation,
} from 'react-router-dom';

import TeamsLayout from 'layouts/teams/TeamsLayout';
// import TeamDetails from 'layouts/teams/TeamDetails';
import TeamDetails from 'pages/teams/team-detail/TeamDetails';
// import AppContext from 'context/Context';

// import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
// import classNames from 'classnames';

// import SiteConstruction from 'components/errors/SiteConstruction';

const TeamsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<TeamsLayout />} />
      <Route path="/:team_record_id" element={<TeamDetails />} />
      <Route path="*" element={<Navigate to="/errors/404" />} />
    </Routes>
  );
};

export default TeamsRoute;
