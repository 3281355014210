export const project_categories = [
  'Animal Sciences (ANIM)',
  'Behavioral and Social Sciences (BEHA)',
  'Biochemistry (BCHM)',
  'Biomedical and Health Sciences (BMED)',
  'Biomedical Engineering (ENBM)',
  'Cellular and Molecular Biology (CELL)',
  'Chemistry (CHEM)',
  'Computational Biology and Bioinformatics (CBIO)',
  'Earth and Environmental Sciences (EAEV)',
  'Embedded Systems (EBED)',
  'Energy: Chemical (EGCH)',
  'Energy: Physical (EGPH)',
  'Engineering Mechanics (ENMC)',
  'Environmental Engineering (ENEV)',
  'Materials Science (MATS)',
  'Mathematics (MATH)',
  'Microbiology (MCRO)',
  'Physics and Astronomy (PHYS)',
  'Plant Sciences (PLNT)',
  'Robotics and Intelligent Machines (ROBO)',
  'Systems Software (SOFT)',
  'Translational Medical Science (TMED)'
];
