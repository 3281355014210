import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import error_icon from 'assets/img/icons/tick-no.svg';

const TokenValidationErrorContent = ({
  status,
  titleText,
  titleTag: TitleTag
}) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={error_icon}
      alt="sent"
      width={80}
    />
    <TitleTag>{titleText}</TitleTag>
    {status === 401 && (
      <p>
        Whoopsies! It seems like the link you clicked on either expired our
        robots couldn't find your account. Please request a new link using the
        button below.
      </p>
    )}
    {status === 403 && (
      <p>
        The link you clicked on has expired. Please request a new link using the
        button below.
      </p>
    )}
    {status === 500 && (
      <p>
        Something went wrong while processing your request, please contact
        eventcoordinator@most.org.
      </p>
    )}

    <Button
      as={Link}
      color="primary"
      size="md"
      className="mt-3"
      to={status === 500 ? '/' : '/auth/forgot-password'}
    >
      {status === 500 ? 'Go back home' : 'Request new link'}
      {status != 500 && (
        <FontAwesomeIcon
          icon="chevron-right"
          transform="shrink-4 down-1"
          className="ms-1"
        />
      )}
    </Button>
  </>
);

TokenValidationErrorContent.propTypes = {
  email: PropTypes.string,
  layout: PropTypes.string,
  titleTag: PropTypes.string,
  status: PropTypes.number,
  titleText: PropTypes.string
};

TokenValidationErrorContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default TokenValidationErrorContent;
