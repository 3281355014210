import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';

const ProjectStatusPill = ({ project_review_status }) => {
  console.log('Project status pill status', project_review_status);
  const backgroundColor = () => {
    switch (project_review_status) {
      case 'Missing Abstract':
      case 'Missing Forms':
        return 'danger';

      case 'Pending Approval':
        return 'warning';

      case 'Approved':
        return 'success';

      default:
        return null;
    }
  };

  return (
    <SoftBadge pill bg={backgroundColor()} className="mx-2">
      {project_review_status}
    </SoftBadge>
  );
};

ProjectStatusPill.propTypes = {
  project_review_status: PropTypes.string
};

export default ProjectStatusPill;
