import React, { useState, useEffect } from 'react';
import PasswordResetForm from '../../components/authentication/PasswordResetForm';
import API from 'configs/api';
import { useParams } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
import TokenValidationSpinner from 'layouts/authentication/tokenValidation/TokenValidationSpinner';
import TokenValidationErrorContent from 'layouts/authentication/tokenValidation/TokenValidationErrorContent';

const renderForm = (tokenValidity, token) => {
  switch (tokenValidity) {
    case 'SUCCESS':
      return (
        <>
          <PasswordResetForm layout="card" hasLabel token={token} />
        </>
      );
    case 'TOKEN_INVALID':
      return (
        <TokenValidationErrorContent
          titleTag="h3"
          titleText="Invalid Link"
          status={401}
        />
      );
    case 'TOKEN_EXPIRED':
      return (
        <TokenValidationErrorContent
          titleTag="h3"
          titleText="Expired Link"
          status={403}
        />
      );
    default:
      return (
        <TokenValidationErrorContent
          titleTag="h3"
          titleText="Server Error"
          status={500}
        />
      );
  }
};

const PasswordReset = () => {
  let { token } = useParams();
  const [validatingToken, setValidatingToken] = useState(true);
  const [tokenValidity, setTokenValidity] = useState('');

  useEffect(async () => {
    console.log(token);
    console.log('Trying now');
    try {
      const { status, data } = await API.get(`/auth/validatetoken/${token}`);
      console.log('server data', data);
      console.log('server status', status);
      if (status === 200 && data.success) setTokenValidity('SUCCESS');
      setValidatingToken(false);
    } catch (err) {
      console.log('err', err);
      console.log(err.response);
      err.response
        ? setTokenValidity(err.response.data.type)
        : setTokenValidity('SERVER_ERROR');
      setValidatingToken(false);
    }
  }, []);

  return (
    <AuthCardLayout>
      <div className="text-center">
        {validatingToken && <TokenValidationSpinner />}
        {!validatingToken && renderForm(tokenValidity, token)}
      </div>
    </AuthCardLayout>
  );
};

export default PasswordReset;
