import React from 'react';
import { logout } from 'helpers/api/auth.api.helper';
import LogoutContent from '../../components/authentication/LogoutContent';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Logout = () => {
  logout();
  return (
    <AuthCardLayout>
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
