import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container, Button, Divider } from '@mui/material';

import API from 'configs/api';

const AwardItem = ({ score, rank }) => {
  function ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  }

  return (
    <Box my={2} textAlign={'center'}>
      <Typography variant="h4" mb={1}>
        {ordinalSuffix(rank)} Place
      </Typography>
      <Typography variant="h5">{score['Team PK']}</Typography>
      <Typography variant="h6">{score.rollup_team_members_name}</Typography>
      <Typography variant="h6">{score.student_schools}</Typography>
    </Box>
  );
};

const AwardsTable = ({ scores, division }) => {
  return (
    <Box mt={4}>
      <Typography
        variant="h4"
        mb={2}
        textAlign={'center'}
        fontWeight={600}
        fontFamily={'Poppins'}
      >
        {division} Top 4 Scores
      </Typography>

      {scores
        .slice()
        .reverse()
        .map((score, index, arr) => (
          <AwardItem score={score} rank={arr.length - index} key={index} />
        ))}
    </Box>
  );
};
const Awards = ({ juniorTopScores, seniorTopScores, event }) => {
  const [publishedScores, setPublishedScores] = useState(
    event.publish_scores || false
  );

  // useEffect to get special awards from API server at route /robotics/special_awards
  const [specialAwards, setSpecialAwards] = useState([]);
  useEffect(() => {
    const getSpecialAwards = async () => {
      try {
        const result = await API.get('robotics/special_awards');
        console.log('Special Awards:', result.data.records_data);
        setSpecialAwards(result.data.records_data);
      } catch (error) {
        console.error(error);
      }
    };
    getSpecialAwards();
  }, []);

  useEffect(() => {
    setPublishedScores(event.publish_scores || false);
  }, [event]);

  const handlePublishScores = async () => {
    console.log('Publishing scores...');
    // POST request to API server route /scores/2023steamboat/publish
    try {
      const result = await API.post('/event/current_event/publish');
      setPublishedScores(true);
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  console.log('Junior Top Scores:', juniorTopScores);
  console.log('Senior Top Scores:', seniorTopScores);
  return (
    <Box mt={4}>
      <Container maxWidth="md">
        <AwardsTable scores={juniorTopScores} division="Junior Division" />
        <Divider sx={{ my: 4 }} />{' '}
        {/* Divider between the senior and junior divisions */}
        <AwardsTable scores={seniorTopScores} division="Senior Division" />
        <Divider sx={{ my: 4 }} />{' '}
        <Box>
          <Typography
            variant="h4"
            mb={2}
            textAlign={'center'}
            fontWeight={600}
            fontFamily={'Poppins'}
          >
            Special Awards
          </Typography>
          {specialAwards &&
            specialAwards.map(award => {
              return (
                <Box my={2} textAlign={'center'} key={award.Name}>
                  <Typography variant="h4" gutterBottom fontWeight={500}>
                    {award.Name}
                  </Typography>
                  <Typography variant="h5">{award.lookup_team_name}</Typography>
                  <Typography variant="h6">
                    {award.lookup_team_students}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Container>

      {/* The Publish Button */}
      <Box mt={4} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePublishScores}
          disabled={publishedScores}
        >
          {publishedScores ? 'Scores Published' : 'Publish Scores'}
        </Button>
      </Box>
    </Box>
  );
};

Awards.propTypes = {
  juniorTopScores: PropTypes.array.isRequired,
  seniorTopScores: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired
};

AwardsTable.propTypes = {
  scores: PropTypes.array.isRequired,
  division: PropTypes.string.isRequired
};

AwardItem.propTypes = {
  score: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired
};

export default Awards;
