import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DataEntryHeaderCard = ({ station }) => {
  return (
    <Card className="bg-100 shadow-none border mb-3">
      <Card.Body className="text-center">
        <h4 className="my-0">2022 Bridges Challenge {station}</h4>
      </Card.Body>
    </Card>
  );
};

export default DataEntryHeaderCard;

DataEntryHeaderCard.propTypes = {
  station: PropTypes.string
};
