import API from 'configs/api';
import React from 'react';
import { Card } from 'react-bootstrap';
import EventsList from './EventsList';

const EventsRegistered = () => {
  console.log('Events registered component');
  const [loadingEvents, setLoadingEvents] = React.useState(true);
  const [myEvents, setMyEvents] = React.useState([]);

  React.useEffect(() => {
    const getMyEvents = async () => {
      const { status, data } = await API.get(
        '/events/registered?view=active_events'
      );
      if (status === 200) {
        const { records_id, records_data } = data;
        setMyEvents({ records_id, records_data });
        setLoadingEvents(false);
      }
      console.log(status, data);
    };

    getMyEvents();
  }, []);

  return (
    <Card>
      <Card.Header className="bg-light">
        <h5>Registered Events</h5>
      </Card.Header>

      <Card.Body>
        {loadingEvents && 'Loading events'}
        {myEvents &&
          myEvents.records_id?.length == 0 &&
          'No registered events. Register for events!'}
        {myEvents && myEvents.records_id?.length > 0 && (
          <EventsList events={myEvents} />
        )}
      </Card.Body>
    </Card>
  );
};

export default EventsRegistered;
