// Import the functions you need from the SDKs you need
// import { storage } from 'firebase/storage';
// import { initializeApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAaCSnQD-3-x1ow4CfW9Ib-wyTh_nALTFU',
  authDomain: 'most-cny-challenge.firebaseapp.com',
  projectId: 'most-cny-challenge',
  storageBucket: 'most-cny-challenge.appspot.com',
  messagingSenderId: '257140989984',
  appId: '1:257140989984:web:88324c182c3485d71f9c45',
  measurementId: 'G-SL9100CHCC'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// export default storage;
