import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SiteConstruction from 'components/errors/SiteConstruction';

// Importing Routes
import EventsRoutes from './events';
import TeamsRoute from './teams';
import { RequireAuth } from './AuthCheck';

const RoutesManager = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/errors/404" />} />
      <Route
        path="dashboard"
        element={
          <RequireAuth>
            <SiteConstruction />
          </RequireAuth>
        }
      />
      <Route path="event/*" element={<EventsRoutes />} />
      <Route path="events/*" element={<EventsRoutes />} />

      <Route path="team/*" element={<TeamsRoute />} />
      <Route
        path="teams/*"
        element={
          <RequireAuth>
            <TeamsRoute />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default RoutesManager;
