import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useForm } from 'react-hook-form';
import { updateRecordData } from 'helpers/api/api.helper';

const TeamInfoView = props => {
  return (
    <div>
      <Row>
        <Col xs={12} md={3}>
          <p className="fw-semi-bold my-0">Team Name</p>
        </Col>
        <Col xs={9} md={7}>
          <p className="my-0">{props?.team_name && props.team_name}</p>
        </Col>
        <Col xs={3} md={2} className="text-end">
          <IconButton
            icon="fa-edit"
            size="sm"
            iconClassName="fs--2"
            variant="outline-secondary"
            onClick={() => props.setEdit(true)}
          />
        </Col>
      </Row>
    </div>
  );
};

const TeamInfoEdit = props => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting }
  } = useForm({ defaultValues: { team_name: props.team_name } });

  const onSubmit = async form_data => {
    console.log('form_data: ', form_data);
    // TODO: call API to update team name
    try {
      const { status, data } = await updateRecordData(
        'teams',
        props.team_id,
        form_data
      );
      if (status === 200) {
        console.log('data: ', data);
        const { record_id, record_data } = data;
        props.setTeam({ record_id, record_data });
        setUpdateSuccessful(true);
        // settimeout to setEdit to false after 2 seconds
        setTimeout(() => {
          props.setEdit(false);
        }, 1500);
      }
    } catch (err) {
      console.error(err);
    }
    // props.setEdit(false);
  };

  // useEffect to setFocus on team_name input field
  useEffect(() => {
    setFocus('team_name');
  }, [setFocus]);

  // updateSuccessful state
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} md={3}>
          <p className="fw-semi-bold my-0">Team Name</p>
        </Col>
        <Col md={true}>
          <Form.Control
            type="text"
            {...register('team_name')}
            disabled={isSubmitting || updateSuccessful}
          />
        </Col>
        <Col xs={12} md={4} className="text-end">
          {updateSuccessful && (
            <Button variant="success" size="sm" className="my-0 disabled">
              Saved
            </Button>
          )}
          {isSubmitting && !updateSuccessful && (
            <Button variant="primary" size="sm" className="my-0" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Submitting...</span>
            </Button>
          )}
          {!isSubmitting && !updateSuccessful && (
            <>
              <Button
                variant="outline-danger"
                size="sm"
                className="my-0"
                onClick={() => props.setEdit(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="my-0 ms-2"
                type="submit"
                // onClick={() => props.setEdit(false)}
              >
                Save
              </Button>
            </>
          )}
        </Col>
      </Row>
    </form>
  );
};

const TeamDetailInfoBody = props => {
  const [edit, setEdit] = useState(false); // edit state: true = edit mode, false = view mode

  return edit ? (
    <TeamInfoEdit
      team_id={props.team_id}
      team_name={props.team_name}
      setEdit={setEdit}
      setTeam={props.setTeam}
    />
  ) : (
    <TeamInfoView team_name={props.team_name} setEdit={setEdit} />
  );
};

TeamDetailInfoBody.propTypes = {
  team_id: PropTypes.string,
  team_name: PropTypes.string,
  setTeam: PropTypes.func
};

TeamInfoView.propTypes = {
  team_name: PropTypes.string,
  setEdit: PropTypes.func
};

TeamInfoEdit.propTypes = {
  team_id: PropTypes.string.isRequired,
  team_name: PropTypes.string,
  setTeam: PropTypes.func,
  setEdit: PropTypes.func
};

export default TeamDetailInfoBody;
