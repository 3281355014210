import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import API from 'configs/api';

const ForgetPasswordForm = ({ setEmailSent }) => {
  // State
  const [emailError, setEmailError] = useState(false);
  const [busy, setBusy] = useState(false);

  // Handler
  const onSubmitData = async form_data => {
    console.log(form_data);
    console.log('Sending password reset request');
    try {
      setBusy(true);
      await API.post('/auth/resetpassword', JSON.stringify(form_data));
      setBusy(false);
      toast.success(
        `An email was sent to ${form_data.email} with the password reset link.`
      );
      setEmailSent({ to: form_data.email, sent: true });
    } catch (error) {
      setBusy(false);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        setEmailError(true);
        setError('email', {
          message:
            'We could not find an account associated with that email address.'
        });
      } else {
        console.error(error);
      }
    }
  };

  // useForm API
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid }
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  return (
    <Form className="mt-4" onSubmit={handleSubmit(onSubmitData)} noValidate>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          name="email"
          isInvalid={emailError}
          // onChange={({ target }) => setEmail(target.value)}
          type="email"
          {...register('email', {
            required: true,
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            },
            onChange: () => {
              emailError && setEmailError(false);
            }
          })}
        />
        <Form.Control.Feedback type="invalid" className="mt-1">
          {errors['email']?.message}
          {errors['email']?.message.includes('could not find') && (
            <span>
              &nbsp;Would you like to <Link to="/auth/register">sign up</Link>{' '}
              instead?
            </span>
          )}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!isValid}>
          {busy ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            >
              <span className="visually-hidden">Logging in...</span>
            </Spinner>
          ) : (
            'Send reset link'
          )}
        </Button>
      </Form.Group>

      <a className="fs--1 text-600" href="mailto:eventcoordinator@most.org">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </a>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string,
  setEmailSent: PropTypes.func
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
