import {
  getEventTeams,
  updateScoreEntryData
} from 'helpers/api/dataentry.api.helper';
import React, { useRef, useState, useEffect } from 'react';
import {
  Card,
  Table,
  InputGroup,
  Button,
  FormControl
  //   Spinner
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
const DataEntryCheckIn = () => {
  const [search, setSearch] = useState(''); // Search term\
  const [loading, setLoading] = useState(true); // Loading state
  //   const [updating, setUpdating] = useState(false); // Updating state
  const [teams, setTeams] = useState([]); // State of teams registered for event
  const [filteredTeams, setFilteredTeams] = useState([]); // State of teams to display
  const searchBox = useRef(null);
  const { event_record_id } = useParams();

  //   useEffect to fetch list of teams from API
  useEffect(() => {
    //   API call to get list of teams for this event
    //   setTeams to the list of teams
    const getTeams = async () => {
      try {
        setLoading(true);
        const { data } = await getEventTeams(event_record_id);
        setTeams(data.records_data);
        setFilteredTeams(data.records_data);
        console.log(data);
        setLoading(false);
      } catch (err) {
        err.response ? console.log(err.response) : console.error(err);
        throw err;
      }
    };

    getTeams();
  }, [event_record_id]);

  const searchButtonHandler = () => {
    if (search) {
      // Clear search box
      setSearch('');
      // Refocus search box
      searchBox.current.focus();
    }
  };

  //  useEffect to filter teams based on search term
  useEffect(() => {
    //   filter teams based on search term
    //   setTeams to filtered list of teams only if search term is not empty
    if (search) {
      const filteredTeams = teams.filter(team => {
        return team.fx_team_fuzzy_search
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setFilteredTeams(filteredTeams);
    } else {
      setFilteredTeams(teams);
    }
  }, [search]);

  const toggleCheckInHandler = async team_id => {
    // Use setTeams to update team's check_in status
    console.log('filtered teams initial state:', filteredTeams);
    console.log('toggling team id check-in', team_id);
    try {
      //   setUpdating(true);
      await updateScoreEntryData(event_record_id, team_id, {
        checked_in: !filteredTeams.find(
          team => team.pk_team_record_id === team_id
        ).checked_in
      });
    } catch (error) {
      console.error(error);
    }

    setTeams(prevTeams => {
      const updatedTeams = prevTeams.map(team => {
        if (team.pk_team_record_id === team_id) {
          console.log('Found the team being updated!', team);
          if (team.checked_in !== undefined) {
            return { ...team, checked_in: undefined };
          } else {
            return { ...team, checked_in: [true] };
          }
        }
        return team;
      });
      console.log(updatedTeams);
      return updatedTeams;
    });

    setFilteredTeams(prevTeams => {
      const updatedTeams = prevTeams.map(team => {
        if (team.pk_team_record_id === team_id) {
          console.log('Found the team being updated!', team);
          if (team.checked_in !== undefined) {
            return { ...team, checked_in: undefined };
          } else {
            return { ...team, checked_in: [true] };
          }
        }
        return team;
      });
      console.log(updatedTeams);
      return updatedTeams;
    });
    // setUpdating(false);
  };

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="mx-4 my-4">
          <InputGroup className="mb-3">
            <FormControl
              size="lg"
              placeholder="Start entering team number or student name to search"
              aria-label="Team lookup"
              autoFocus={true}
              value={search}
              ref={searchBox}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
            <Button
              variant="outline-secondary"
              id="search-button"
              type="submit"
              onClick={searchButtonHandler}
            >
              {search ? 'Clear' : 'Search'}
            </Button>
          </InputGroup>
        </div>
        {loading ? (
          <div className="mx-4 my-5 text-center">
            <p>Loading teams...</p>
          </div>
        ) : (
          <Table responsive="md">
            <thead>
              <tr>
                <th scope="col" style={{ width: '15%' }}>
                  Team Number
                </th>
                <th scope="col">Team Name</th>
                <th scope="col">Team Members</th>
                <th scope="col" className="d-none d-lg-table-cell">
                  School
                </th>
                <th
                  scope="col"
                  className="text-end"
                  style={{ minWidth: '125px' }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {filteredTeams?.map(team => {
                // If team.checked_in is not undefined, then the team has been checked in
                return (
                  <tr key={team.pk_team_record_id}>
                    <td>{team.team_num}</td>
                    <td>{team.team_name}</td>
                    <td>{team.rollup_team_members_names}</td>
                    <td className="d-none d-lg-table-cell">
                      {team.rollup_team_school}
                    </td>
                    <td className="text-end">
                      <Button
                        variant={
                          team.checked_in === undefined || !team.checked_in
                            ? 'secondary'
                            : 'outline-danger'
                        }
                        size="sm"
                        onClick={() =>
                          toggleCheckInHandler(team.pk_team_record_id)
                        }
                      >
                        {team.checked_in === undefined || !team.checked_in
                          ? 'Check In'
                          : 'Withdraw'}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataEntryCheckIn;
