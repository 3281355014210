import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, Nav } from 'react-bootstrap';
import LoginModal from 'components/authentication/LoginModal';
import { getUserName, isLoggedIn } from 'helpers/api/auth.api.helper';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar_white.png';

const breakpoint = 'lg';

const ProfileDropdown = ({ showDropdown, nameClickHandler }) => {
  return (
    <Dropdown
      navbar={true}
      as="li"
      show={showDropdown}
      className="d-none d-lg-block"
    >
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
        onClick={() => {
          nameClickHandler();
        }}
      >
        <Avatar src={avatar} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/dashboard">
            My Dashboard
          </Dropdown.Item>
          {/* <Dropdown.Item as={Link} to="/user/profile">
                Profile &amp; account
              </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/auth/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
const LoggedInNav = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const nameClickHandler = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <>
      <Nav.Link
        className="text-white fs-0 d-none d-lg-block"
        onClick={() => {
          nameClickHandler();
        }}
      >{`Hello, ${getUserName()}`}</Nav.Link>
      <ProfileDropdown
        showDropdown={showDropdown}
        nameClickHandler={nameClickHandler}
      />
      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/dashboard"
          className={`d-${breakpoint}-none d-xl-none`}
        >
          Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/auth/logout"
          className={`d-${breakpoint}-none d-xl-none`}
        >
          Logout
        </Nav.Link>
      </Nav.Item>
    </>
  );
};

const NotLoggedInNav = ({ setShowLoginModal }) => {
  return (
    <>
      <Nav.Link
        onClick={() => {
          setShowLoginModal(true);
        }}
      >
        Login
      </Nav.Link>
      <Nav.Link as={Link} to="/auth/register">
        Register
      </Nav.Link>
    </>
  );
};

const LandingRightSideNavItem = () => {
  const is_loggedIn = isLoggedIn();
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <Nav navbar className="ms-auto flex-col align-items-center">
      {is_loggedIn && <LoggedInNav />}
      {!is_loggedIn && <NotLoggedInNav setShowLoginModal={setShowLoginModal} />}
      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
        />
      )}

      <Nav.Link
        href="https://sites.google.com/view/moststemchallenge/home"
        target="_blank"
      >
        Support
      </Nav.Link>
    </Nav>
  );
};

NotLoggedInNav.propTypes = {
  setShowLoginModal: PropTypes.func
};

ProfileDropdown.propTypes = {
  showDropdown: PropTypes.bool,
  nameClickHandler: PropTypes.func
};

export default LandingRightSideNavItem;
