import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const SectionHeader = ({ title, subtitle, ...rest }) => {
  return (
    <Box
      {...rest}
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      px={2} // Padding on the x-axis for mobile users
    >
      <Typography
        variant="h3"
        component="h3"
        mb={2}
        fontFamily="Poppins"
        fontWeight={700}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" component="p" className="lead">
        {subtitle}
      </Typography>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default SectionHeader;
