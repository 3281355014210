import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkmark from 'assets/img/icons/checkmark.svg';

const ConfirmMailContent = ({ titleTag: TitleTag }) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={checkmark}
      alt="sent"
      width={80}
    />
    <TitleTag>You're all set!</TitleTag>
    <p>
      Thank you for verifying your email. You may now log in to register for
      events and manage your teams.
    </p>
    <Button
      as={Link}
      color="primary"
      size="md"
      className="mt-3"
      to="/auth/login"
    >
      Proceed to login
      <FontAwesomeIcon
        icon="chevron-right"
        transform="shrink-4 down-1"
        className="ms-1"
      />
    </Button>
  </>
);

ConfirmMailContent.propTypes = {
  email: PropTypes.string,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
