import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';

const PasswordResetContent = ({ email, titleTag: TitleTag }) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={envelope}
      alt="sent"
      width={100}
    />
    <TitleTag>Please check your email!</TitleTag>
    <p>
      A password reset email has been sent to <strong>{email}</strong>. Please
      click on the provided link to reset your password.
      <br />
      <br />
      If you do not receive the password reset email within a few minutes,
      please check your SPAM or junk mail folder.
    </p>
    <Button
      as={Link}
      color="primary"
      size="sm"
      className="mt-3"
      to={`/auth/login`}
    >
      <FontAwesomeIcon
        icon="chevron-left"
        transform="shrink-4 down-1"
        className="me-1"
      />
      Return to login
    </Button>
  </>
);

PasswordResetContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

PasswordResetContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default PasswordResetContent;
