import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import SiteConstruction from 'components/errors/SiteConstruction';
import DataEntryHeaderCard from './DataEntryHeaderCard';
import DataEntryCheckIn from './DataEntryCheckIn';

// Function that returns the name of the current path
const getCurrentStationName = location => {
  let lastSlash = location.pathname.lastIndexOf('/');
  let data_entry_station = location.pathname.substring(lastSlash + 1);

  // Switch statement to return the name of the station
  //   If the value is check-in, return Check-in
  //   If the value is inspection, return Inspection
  //   If the value is test-results, return Test Results
  //   If the value is special-awards, return Special Awards
  //   If the value is none of the above, return Data Entry
  switch (data_entry_station) {
    case 'check-in':
      return 'Check-in';
    case 'inspection':
      return 'Inspection';
    case 'test-results':
      return 'Test Results';
    case 'special-awards':
      return 'Special Awards';
    default:
      return 'Data Entry';
  }
};

const DataEntryRoutes = () => {
  let location = useLocation();
  console.log('Current location: ', location, location.pathname);
  const station_name = getCurrentStationName(location);

  return (
    <>
      <DataEntryHeaderCard station={station_name} />
      <Routes>
        <Route path="*" element={<Navigate to="/errors/404" />} />
        <Route path="/" element={<SiteConstruction />} />
        <Route path="/check-in" element={<DataEntryCheckIn />} />
        <Route path="/inspection" element={<p>Inspection</p>} />
        <Route path="/test-results" element={<p>Test Results</p>} />
        <Route path="/special-awards" element={<p>Special Awards</p>} />
      </Routes>
    </>
  );
};

export default DataEntryRoutes;
