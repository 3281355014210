import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';

const TeamDetailsHeader = ({ team_name, withdrawn }) => {
  const navigate = useNavigate();
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col xs={3} md={true}>
            <Button
              variant="link"
              className="m-0 p-0"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon="arrow-left" /> Back
            </Button>
          </Col>
          <Col xs={8}>
            <h5 className="mb-0 text-center">
              {team_name} Team Details{' '}
              {withdrawn && (
                <SoftBadge pill bg="danger" className="mx-2">
                  Withdrew from event
                </SoftBadge>
              )}
            </h5>
          </Col>
          <Col xs="1" md={true}></Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

TeamDetailsHeader.propTypes = {
  team_name: PropTypes.string,
  withdrawn: PropTypes.bool
};

export default TeamDetailsHeader;
