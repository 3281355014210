import React from 'react';
import eventBanner from 'assets/img/background/black_background_1080p.png';
import { Card, Row, Col, Button, Placeholder } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import { Link, useSearchParams } from 'react-router-dom';
// import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import styles from './EventDetailHeader.module.css';
import classNames from 'classnames';
import { renderRegistrationButtonStatus } from 'helpers/utils';

const EventDetailHeader = ({ event }) => {
  // console log URL queries
  const [searchParams] = useSearchParams();
  console.log('searchParams: ', searchParams);
  const override = searchParams.get('override');
  return (
    <Card className="p-0 mb-3">
      {!!eventBanner && (
        <img
          className={classNames('card-img-top', styles['card-img'])}
          src={eventBanner}
          alt=""
        />
      )}
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              {!!event && (
                <Calendar
                  month={event?.event_date_month}
                  day={event?.event_date_day}
                />
              )}
              <Flex
                direction={'column'}
                justifyContent={'center'}
                className="fs--1 ms-3"
              >
                {!event ? (
                  <Placeholder as="h5" animation="glow" className="fs-1">
                    <Placeholder style={{ width: '30rem' }} />
                  </Placeholder>
                ) : (
                  <h5 className="fs-0 text-capitalize">{event?.event_name}</h5>
                )}
              </Flex>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Flex>
              <Button
                as={
                  override || event?.registration_status === 'reg_open'
                    ? Link
                    : ''
                }
                variant="falcon-primary"
                size="sm"
                className="px-4 px-sm-5"
                to={'register'}
                disabled={
                  event?.registration_status !== 'reg_open' && !override
                }
              >
                {override
                  ? 'Register team'
                  : renderRegistrationButtonStatus(
                      event?.registration_status,
                      event?.reg_date_open
                    )}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventDetailHeader.propTypes = {
  event: PropTypes.object
};

export default EventDetailHeader;
