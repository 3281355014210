import React, { useEffect, useState } from 'react';
import Section from 'components/common/Section';
import SectionHeader from 'components/pages/landing/SectionHeader';
import { Table } from 'react-bootstrap';
import {
  getRoboticsJuniorScores,
  getRoboticsSeniorScores
} from 'helpers/api/dataentry.api.helper';

const RoboticsScoreboard = () => {
  // useEffect to retrieve scoreboard data from Airtable
  const [loading, setLoading] = useState(true);
  const [juniorScores, setJuniorScores] = useState([]);
  const [seniorScores, setSeniorScores] = useState([]);

  useEffect(() => {
    const retrieveScoreboard = async () => {
      try {
        setLoading(true);
        const jr_score_data = await getRoboticsJuniorScores();
        const sr_score_data = await getRoboticsSeniorScores();
        console.log('jr_score_data', jr_score_data.data);
        console.log('sr_score_data', sr_score_data.data);
        setJuniorScores(jr_score_data.data.records_data);
        setSeniorScores(sr_score_data.data.records_data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    retrieveScoreboard();
  }, []);

  return (
    <div>
      <Section>
        <SectionHeader
          title="2024 Micron Robotics Challenge Scoreboard"
          subtitle=""
        />
        {loading && <h3 className="text-center mt-4">Loading scoreboard...</h3>}
        {!loading && (
          <>
            <h4>Senior Division</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Team</th>
                  <th scope="col">Team Members</th>
                  <th scope="col">Team School</th>
                  <th scope="col" style={{ width: '200px' }}>
                    Special Awards
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* map senior score into table */}
                {seniorScores.map((item, index) => (
                  <tr
                    style={{
                      fontWeight:
                        index < 4 && item.robotics_score > 0 ? 'bold' : 'normal'
                    }}
                    key={index}
                  >
                    <td>{item.robotics_score > 0 ? index + 1 : 'DNQ'}</td>
                    <td>{item['Team PK']}</td>
                    <td>{item.rollup_team_members_names}</td>
                    <td>{item.rollup_team_school}</td>
                    <td>{item.special_award}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h4 className="mt-3">Junior Division</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Team</th>
                  <th scope="col">Team Members</th>
                  <th scope="col">Team School</th>
                  <th scope="col" style={{ width: '200px' }}>
                    Special Awards
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* map junior score into table */}
                {juniorScores.map((item, index) => (
                  <tr
                    style={{
                      fontWeight:
                        index < 4 && item.robotics_score > 0 ? 'bold' : 'normal'
                    }}
                    key={index}
                  >
                    <td>{item.robotics_score > 0 ? index + 1 : 'DNQ'}</td>
                    <td>{item['Team PK']}</td>
                    <td>{item.rollup_team_members_names}</td>
                    <td>{item.rollup_team_school}</td>
                    <td>{item.special_award}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Section>
    </div>
  );
};

export default RoboticsScoreboard;
