import React from 'react';
import { Spinner } from 'react-bootstrap';

const TokenValidationSpinner = () => {
  return (
    <>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Validating token...</span>
      </Spinner>
      <h5 className="mt-2">Validating token...</h5>
    </>
  );
};

export default TokenValidationSpinner;
