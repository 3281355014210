import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Box,
  LinearProgress,
  Divider,
  FormControl,
  IconButton,
  Button
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import Skeleton from '@mui/material/Skeleton';
import ReactSelect from 'react-select';
import { useTeamsContext } from 'context/TeamsContext'; // Adjust the import path as necessary
import {
  getBridgesScoresByYear,
  updateSpecialAward,
  getSpecialAwardTeams,
  togglePublishedScore,
  getEventDetails
} from 'helpers/api/airtable.api';

const BridgesGameDay = () => {
  const eventID = 'recOemN51VZz1zrMS'; // Define your eventID here
  const { teams, loading: teamsLoading } = useTeamsContext(); // Access the teams and loading state from TeamsContext
  const [localLoading, setLocalLoading] = useState(true);
  const [juniorScores, setJuniorScores] = useState([]);
  const [seniorScores, setSeniorScores] = useState([]);
  const [totalTeams, setTotalTeams] = useState(null);
  const [specialAwards, setSpecialAwards] = useState({
    bestTeamNameJunior: null,
    bestTeamNameSenior: null,
    bestTeamSpirit: null
  });
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const [isPublished, setIsPublished] = useState(false);

  // Mapping of award names to their record IDs
  const awardRecordIDs = {
    bestTeamNameJunior: 'recRxWhZzBuNw9OLd',
    bestTeamNameSenior: 'reczdRlr9oWUjAxJ2',
    bestTeamSpirit: 'rec3nK57AYnCGsDkE'
  };

  // Fetch initial award assignments from Airtable
  const fetchInitialAssignments = async () => {
    try {
      const assignments = {};

      // Loop through each awardRecordID and fetch the teams' IDs
      for (const [award, recordID] of Object.entries(awardRecordIDs)) {
        const teamIDs = await getSpecialAwardTeams(recordID);

        if (teamIDs && teamIDs.length > 0) {
          // Find the corresponding team object in the teams context
          const team = teams.find(
            team => team.pk_team_record_id === teamIDs[0]
          );

          if (team) {
            assignments[award] = {
              value: team.pk_team_record_id,
              label: team['Team PK']
            };
          } else {
            assignments[award] = null;
          }
        } else {
          assignments[award] = null;
        }
      }

      setSpecialAwards(assignments);
    } catch (error) {
      console.error('Error fetching initial award assignments:', error);
    }
  };

  const fetchEventDetails = async () => {
    try {
      const eventDetails = await getEventDetails(eventID);
      setIsPublished(eventDetails.publish_scores || false);
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };

  const refreshData = async () => {
    setLocalLoading(true);
    await fetchInitialAssignments();
    if (teams.length > 0) {
      const totalTeamsForEvent = teams.filter(team =>
        team.fk_ext_registered_event_id?.includes(eventID)
      ).length;
      setTotalTeams(totalTeamsForEvent);
    }
    const currentYearScores = (await getBridgesScoresByYear(2024)).map(
      score => score.fields
    );
    setJuniorScores(
      currentYearScores.filter(score =>
        score.lookup_team_division.includes('Junior')
      )
    );
    setSeniorScores(
      currentYearScores.filter(score =>
        score.lookup_team_division.includes('Senior')
      )
    );
    setLocalLoading(false);
    setLastUpdated(new Date()); // Update the last updated time
    setCountdown(300); // Reset the countdown
  };

  useEffect(() => {
    fetchEventDetails(); // Fetch event details
    refreshData(); // Initial data fetch

    // Set interval to refresh data every 5 minutes (300000 milliseconds)
    const interval = setInterval(refreshData, 300000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [teams, eventID]);

  useEffect(() => {
    // Countdown timer for the next refresh
    const timer = setInterval(() => {
      setCountdown(prev => (prev > 0 ? prev - 1 : 300));
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, []);

  const formatTime = date =>
    `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;

  const formatCountdown = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  };

  const handleAwardChange = async (award, selectedOption) => {
    setSpecialAwards({ ...specialAwards, [award]: selectedOption });

    const awardRecordID = awardRecordIDs[award];
    const updatedFields = selectedOption
      ? { teams: [selectedOption.value] } // If option selected, update with team ID
      : { teams: [] }; // If cleared, update with empty array

    try {
      await updateSpecialAward(awardRecordID, updatedFields);
      console.log(
        `Award ${award} ${selectedOption ? 'updated' : 'cleared'} successfully`
      );
    } catch (error) {
      console.error(`Failed to update award ${award}:`, error);
    }
  };

  const togglePublishStatus = async () => {
    try {
      await togglePublishedScore(eventID);
      setIsPublished(!isPublished); // Toggle the published state
    } catch (error) {
      console.error('Error toggling publish status:', error);
    }
  };

  const refreshPage = () => window.location.reload();
  const printPage = () => window.print();

  const getTopScores = scores =>
    scores.sort((a, b) => b.calculated_score - a.calculated_score).slice(0, 4);

  // Generate team options for dropdowns
  const juniorTeamOptions = teams
    .filter(
      team =>
        team.team_category?.includes('Junior') &&
        team.fk_ext_registered_event_id?.includes(eventID)
    )
    .map(team => ({
      value: team.pk_team_record_id,
      label: team['Team PK']
    }));

  const seniorTeamOptions = teams
    .filter(
      team =>
        team.team_category?.includes('Senior') &&
        team.fk_ext_registered_event_id?.includes(eventID)
    )
    .map(team => ({
      value: team.pk_team_record_id,
      label: team['Team PK']
    }));

  const teamSpiritOptions = teams
    .filter(team => team.fk_ext_registered_event_id?.includes(eventID))
    .map(team => ({
      value: team.pk_team_record_id,
      label: team['Team PK']
    }));

  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
      >
        <Typography variant="h4" gutterBottom>
          2024 Bridges Challenge Game Day
        </Typography>
        <Box sx={{ '@media print': { display: 'none' } }}>
          <IconButton onClick={refreshPage} aria-label="Refresh page">
            <RefreshIcon />
          </IconButton>
          <IconButton onClick={printPage} aria-label="Print page">
            <PrintIcon />
          </IconButton>
        </Box>
      </Box>

      {localLoading ? (
        <Box
          my={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1" mb={2}>
            Loading event and team data...
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box my={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1">
                Scores Entered: {juniorScores.length + seniorScores.length} /{' '}
                {teamsLoading ? <CircularProgress size={20} /> : totalTeams}
              </Typography>
              <Typography variant="subtitle2">
                (Last Updated: {formatTime(lastUpdated)} | Next Refresh In:{' '}
                {formatCountdown(countdown)})
              </Typography>
            </Box>
            {totalTeams !== null && !teamsLoading ? (
              <LinearProgress
                variant="determinate"
                value={
                  ((juniorScores.length + seniorScores.length) / totalTeams) *
                  100
                }
              />
            ) : (
              <Skeleton variant="rectangular" height={10} />
            )}
          </Box>

          <Grid container spacing={3} my={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Top 4 Junior Division</Typography>
                <Divider sx={{ marginY: 1 }} />
                {getTopScores(juniorScores).map((team, index) => (
                  <Typography key={index}>
                    {index + 1}. {team.team_num_name}{' '}
                    {team.lookup_team_school_manual != '' &&
                      `(${team.lookup_team_school_manual})`}
                  </Typography>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Top 4 Senior Division</Typography>
                <Divider sx={{ marginY: 1 }} />
                {getTopScores(seniorScores).map((team, index) => (
                  <Typography key={index}>
                    {index + 1}. {team.team_num_name}{' '}
                    {team.lookup_team_school_manual != '' &&
                      `(${team.lookup_team_school_manual})`}
                  </Typography>
                ))}
              </Paper>
            </Grid>
          </Grid>

          <Box my={3}>
            <Typography variant="h5">Special Awards</Typography>
            <Grid container spacing={2} my={0}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Best Team Name (Junior)
                </Typography>
                <FormControl fullWidth>
                  <ReactSelect
                    options={juniorTeamOptions}
                    placeholder="Select a team"
                    onChange={selectedOption =>
                      handleAwardChange('bestTeamNameJunior', selectedOption)
                    }
                    value={specialAwards.bestTeamNameJunior}
                    isClearable
                    isDisabled={teamsLoading}
                    isLoading={teamsLoading}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Best Team Name (Senior)
                </Typography>
                <FormControl fullWidth>
                  <ReactSelect
                    options={seniorTeamOptions}
                    placeholder="Select a team"
                    onChange={selectedOption =>
                      handleAwardChange('bestTeamNameSenior', selectedOption)
                    }
                    value={specialAwards.bestTeamNameSenior}
                    isClearable
                    isDisabled={teamsLoading}
                    isLoading={teamsLoading}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Best Team Spirit
                </Typography>
                <FormControl fullWidth>
                  <ReactSelect
                    options={teamSpiritOptions}
                    placeholder="Select a team"
                    onChange={selectedOption =>
                      handleAwardChange('bestTeamSpirit', selectedOption)
                    }
                    value={specialAwards.bestTeamSpirit}
                    isClearable
                    isDisabled={teamsLoading}
                    isLoading={teamsLoading}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Publish/Unpublish Button */}
          <Box
            sx={{
              '@media print': { display: 'none' },
              textAlign: 'center',
              mt: 4
            }}
          >
            <Button
              variant="contained"
              color={isPublished ? 'error' : 'success'}
              onClick={togglePublishStatus}
            >
              {isPublished ? 'Unpublish Results' : 'Publish Results'}
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default BridgesGameDay;
