// Importing libraries and dependencies
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { AuthWizardContext } from 'context/Context';
import { Card, Form, Nav } from 'react-bootstrap';
import API from 'configs/api';

// Importing common components
import IconButton from 'components/common/IconButton';

// Importing different registration page forms
import RegAccountForm from './RegAccountForm';
import RegPersonalForm from './RegPersonalForm';
import RegSuccess from './RegSuccess';
import RegWizardModal from './RegWizardModal';

const RegWizardLayout = ({ validation }) => {
  // validation is prop passed from Registration parent
  const { user, setUser, step, setStep, set_tbFields } =
    useContext(AuthWizardContext);
  const {
    register,
    trigger,
    handleSubmit,
    setError,
    formState: { dirtyFields, errors, isValid },
    watch,
    setValue,
    reset,
    clearErrors,
    setFocus
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'lock',
      label: 'Account'
    },
    {
      icon: 'user',
      label: 'Personal'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  // After every page, save data to ContextStore
  const onSubmitData = async form_data => {
    setUser({ ...user, ...form_data });
    const { ['confirmPassword']: confirm_password, ...final_form_data } =
      form_data;

    try {
      console.log('Sending form data to server...');
      console.log(confirm_password);
      const response = await API.post(
        '/users',
        JSON.stringify(final_form_data)
      );
      console.log('Response from server:', response);
      setStep(step + 1);
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        if (error.response.status == 400) {
          set_tbFields(prevState => {
            return { ...prevState, email: true };
          });
          setStep(step + 1);
        } else if (error.response.status == 409) {
          setError('email', { message: 'Email already exists.' });
          setFocus('email');
        }
      } else {
        console.error(error);
      }
    }
  };

  // If not validating errors, move up one step
  const onError = errors => {
    console.log('Touched fields:');
    console.log(dirtyFields);
    console.log(errors);
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
    // Do stuff if there is error
  };

  // Show/hide modal if on last page
  const toggle = () => setModal(!modal);

  // Allow users to navigate back and forth between registration pages
  const handleNavs = targetStep => {
    if (step !== navItems.length) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <RegWizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header className={classNames('bg-light pb-2')}>
          <Nav className="justify-content-center">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                index={index + 1}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
              />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <RegAccountForm
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              setError={setError}
              trigger={trigger}
            />
          )}
          {step === 2 && (
            <RegPersonalForm
              register={register}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          )}
          {step === 3 && <RegSuccess reset={reset} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === navItems.length,
            ' d-flex': step < navItems.length
          })}
        >
          <IconButton
            variant="link"
            icon={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            disabled={!isValid}
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

RegWizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool
};

NavItem.propTypes = {
  index: PropTypes.number,
  step: PropTypes.number,
  handleNavs: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string
};

export default RegWizardLayout;
