import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import LandingRightSideNavItem from 'components/pages/landing/LandingRightSideNavItem';
import { isLoggedIn } from 'helpers/api/auth.api.helper';
// import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
// import CartNotification from 'components/navbar/top/CartNotification';
// import NineDotMenu from './NineDotMenu';

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const logged_in = isLoggedIn();

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {logged_in && (
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isDark', !isDark)}
          >
            <OverlayTrigger
              key="left"
              placement={'left'}
              overlay={
                <Tooltip id="ThemeColor">
                  {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
      )}

      {/* <CartNotification /> */}
      {/* <NotificationDropdown /> */}
      {/* <NineDotMenu /> */}
      {logged_in && <ProfileDropdown />}
      {!logged_in && <LandingRightSideNavItem />}
      <Nav.Item>{/* <Nav.Link>Dashboard</Nav.Link> */}</Nav.Item>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
