import React, { useEffect, useState } from 'react';
import Section from 'components/common/Section';
import SectionHeader from 'components/pages/landing/SectionHeader';
import {
  getBridgesScoresByYear,
  getEventDetails
} from 'helpers/api/airtable.api';
import { ScoreTable } from 'components/pages/gameday/ScoreTable';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download'; // Import Download Icon

const BridgeScoreboard = () => {
  const [loading, setLoading] = useState(true);
  const [juniorScores, setJuniorScores] = useState([]);
  const [seniorScores, setSeniorScores] = useState([]);
  const [isEventPublished, setIsEventPublished] = useState(false);
  const [certificateURL, setCertificateURL] = useState(''); // State to hold the certificate URL
  const eventID = 'recOemN51VZz1zrMS';

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventDetails = await getEventDetails(eventID);
        console.log('eventDetails', eventDetails);
        setIsEventPublished(eventDetails.publish_scores || false);

        if (eventDetails.participation_certificate?.length > 0) {
          setCertificateURL(eventDetails.participation_certificate[0].url);
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    const retrieveScoreboard = async () => {
      try {
        setLoading(true);
        const currentYearScores = (await getBridgesScoresByYear(2024)).map(
          score => score.fields
        );

        const jrScoresData = currentYearScores.filter(score =>
          score.lookup_team_division.includes('Junior')
        );
        const srScoresData = currentYearScores.filter(score =>
          score.lookup_team_division.includes('Senior')
        );

        setJuniorScores(jrScoresData);
        setSeniorScores(srScoresData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    };

    // Fetch event details and scoreboard data
    fetchEventDetails();
    retrieveScoreboard();
  }, [eventID]);

  return (
    <Section>
      <SectionHeader
        title="2024 Bridges Challenge Scoreboard"
        // subtitle={
        //   isEventPublished &&
        //   "Scores are calculated by calculating the bridge's efficiency."
        // }
      />
      {certificateURL && (
        <Box mt={4} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            href={certificateURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Participation Certificate
          </Button>
        </Box>
      )}
      {loading && isEventPublished && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}
      {!loading && isEventPublished && (
        <Box mt={4}>
          <ScoreTable division="Senior Division" scores={seniorScores} />
          <ScoreTable division="Junior Division" scores={juniorScores} />
        </Box>
      )}
      {!isEventPublished && (
        <Box mt={4} textAlign="center">
          <Typography variant="h6">
            The event is ongoing, and scores will be published shortly following
            the awards ceremony. Please check back soon.
          </Typography>
        </Box>
      )}
    </Section>
  );
};

export default BridgeScoreboard;
