import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import Dashboard from 'components/dashboards/default';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
// import MainRoutes from './MainRoutes';
// import Footer from 'components/footer/Footer';
// import ProductProvider from 'components/app/e-commerce/ProductProvider';
// import classNames from 'classnames';

// Importing Routes
import RoutesManager from 'routes';

// Helper Functions
import { isLoggedIn } from 'helpers/api/auth.api.helper';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  // const { path, url } = useRouteMatch();
  // const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // console.log(hash);
  // console.log(pathname);
  // console.log(path);
  // console.log(url);

  return (
    <div className={'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
        isLoggedIn() && <NavbarVertical />}
      <div className="content">
        <NavbarTop />
        <RoutesManager />
      </div>
    </div>

    // <div className={'container'}>
    //   {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
    //     <NavbarVertical />
    //   )}
    //   <ProductProvider>
    //     <div className={classNames('content', { 'pb-0': isKanban })}>
    //       <NavbarTop />
    //       <Routes>
    //         <Route path="/" exact component={Dashboard} />
    //         <MainRoutes />
    //       </Routes>
    //       {!isKanban && <Footer />}
    //     </div>
    //   </ProductProvider>
    // </div>
  );
};

export default MainLayout;
