import API from 'configs/api';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

export const getTeamsData = async () => {
  const { user_group } = cookie.get('user');
  return user_group === 'admin' ? await getAllTeams() : await getUserTeams();
};

export const getTeamData = async team_record_id => {
  return API.get(`/teams/${team_record_id}`);
};

export const getAllTeams = async () => {
  return API.get('/teams/all');
};

export const getUserTeams = async () => {
  return API.get('/teams');
};

export const deleteTeam = async recordID => {
  return API.delete(`/teams/${recordID}`);
};

export const getTeamMemberDetails = async studentRecordID => {
  return API.get(`/students/${studentRecordID}`);
};

export const withdrawTeamFromEvent = async team_record_id => {
  return API.put(`/teams/${team_record_id}`, {
    widthdrawn: true
  });
};

export const getParsedTeamTableData = async () => {
  try {
    const res = await getTeamsData();
    if (res.status > 300) {
      console.log('teamsapihelper oh hell naw');
      throw new Error('SERVER_ERROR');
    } else {
      const teams_parsed_data = res.data.data.map(team => {
        return {
          team_num: team.team_num,
          registration_status: team.registration_status,
          team_name: team.team_name,
          team_members: team.team_members_rollup,
          team_category: team.team_category,
          team_record_id: team.team_record_id
        };
      });
      return teams_parsed_data;
    }
  } catch (err) {
    err.response ? console.log(err.response) : console.error(err);
    throw err;
  }
};

export const getProjectData = async projectID => {
  return API.get(`/cnysefprojs/${projectID}`);
};

export const updateProjectData = async (projectID, update_data) => {
  return API.put(`/cnysefprojs/${projectID}`, update_data);
};

export const getEventData = async eventID => {
  return API.get(`/event/${eventID}`);
};

export const getMyRegisteredTeams = async eventID => {
  const { user_id } = cookie.get('user');
  return API.get(
    `/teams?view=registered_teams&sortParams=withdrawn:asc&search=rollup_team_leader_id:${user_id},rollup_registered_event_id:${eventID}`
  );
};

export const toggleTeamWithdrawnStatus = async teamID => {
  const { data } = await API.get(`/teams/${teamID}`);
  const { withdrawn } = data.record_data;
  return API.put(`/teams/${teamID}`, { withdrawn: !withdrawn });
};
