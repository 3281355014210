import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';

import PropTypes from 'prop-types';
import RegWizardLayout from 'layouts/authentication/registration/RegWizardLayout';
import AuthWizardProvider from 'components/wizard/AuthWizardProvider';

const RegistrationWizard = ({ variant, validation, progressBar }) => {
  return (
    <AuthWizardProvider>
      <RegWizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </AuthWizardProvider>
  );
};

const Registration = () => {
  return (
    <Section className="py-0">
      <Row className="justify-content-center pt-6">
        <Col sm={10} lg={7} className="col-xxl-5">
          <Logo width={200} />
          <RegistrationWizard validation={true} />
        </Col>
      </Row>
    </Section>
  );
};

RegistrationWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default Registration;
