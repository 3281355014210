import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import API from 'configs/api';
import { project_categories } from 'data/sciencefair/projectcategories';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getSize } from 'helpers/utils';
import { useDropzone } from 'react-dropzone';

import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from 'configs/firebase';

const TeamProjectEdit = ({
  projectData,
  setProjectData,
  team_category,
  setEditMode,
  saveRef,
  setSubmittingStatus,
  isFormValid
}) => {
  console.log('Team Project Edit component');
  console.log('Project Data', projectData);
  var defaultValues = {
    project_title: projectData.project_title,
    project_category: projectData.project_category,
    project_abstract: projectData.project_abstract,
    restricted_humans: projectData.restricted_humans,
    restricted_animals: projectData.restricted_animals,
    restricted_biohazard: projectData.restricted_biohazard,
    restricted_hazard: projectData.restricted_hazard
  };

  const project_categories_options = project_categories.map(
    project_category => {
      return { value: project_category, label: project_category };
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange', defaultValues });

  // Update isFormValid state when isValid changes
  useEffect(() => {
    isFormValid(isValid);
  }, [isValid]);

  // File upload state handler
  const [files, setFiles] = useState([]);

  // Remove File handler
  const removeFileHandler = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  // Initializing File Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,.doc,.pdf,.docx,.txt',
    onDrop: acceptedFiles => setFiles(acceptedFiles)
  });

  // Firebase file upload
  const firebaseUploadFiles = file => {
    if (!file) return;

    // Replace spaces with underscores
    let filename = file.name.replace(/\s/g, '_');

    const randomFolder = crypto.randomUUID();
    const storageRef = ref(
      storage,
      `/public/uploads/${randomFolder}/${filename}`
    );
    return uploadBytesResumable(storageRef, file);
  };

  const onSubmit = async form_data => {
    setSubmittingStatus(true);
    form_data.project_category = form_data.project_category.value;
    console.log('attached files', files);

    Promise.all(files.map(file => firebaseUploadFiles(file)))
      .then(ref_array =>
        Promise.all(ref_array.map(ref_item => getDownloadURL(ref_item.ref)))
          .then(async url_arr => {
            console.log('file urls', url_arr);
            const newFilesArray = url_arr.map(url => {
              const startSlice =
                url.indexOf('public%2Fuploads%2F') +
                'public%2Fuploads%2F'.length +
                39;
              const endSlice = url.indexOf('?alt');
              return { url: url, filename: url.slice(startSlice, endSlice) };
            });
            const old_attached_forms =
              'attached_forms' in projectData ? projectData.attached_forms : '';
            form_data.attached_forms = [
              ...newFilesArray,
              ...old_attached_forms
            ];
            console.log(JSON.stringify(form_data));
            try {
              console.log('Sending data to server...');
              const server_data = await API.put(
                `/cnysef_projects/${projectData.project_id}`,
                JSON.stringify(form_data)
              );
              console.log(server_data);
              console.log(server_data.statusText);
              console.log(server_data.data);
              const { record_id, record_data } = server_data.data;
              setProjectData({ record_id, record_data });
              setTimeout(() => {
                setEditMode(false);
                setSubmittingStatus(false);
              }, 10);
            } catch (error) {
              setSubmittingStatus(false);
              if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
              } else {
                console.error(error);
              }
            }
          })
          .catch(err => console.log('url fetch error', err))
      )
      .catch(error => console.log('PROMISE ERROR', error));
  };

  const onError = errors => {
    console.log(errors);
    reset(defaultValues);
  };

  return (
    <>
      <Form ref={saveRef} onSubmit={handleSubmit(onSubmit, onError)}>
        <Card.Body className="bg-light py-3">
          <Form.Group as={Row} className="mb-3" controlId="project_title">
            <Form.Label column sm={12} md={3} xxl={2}>
              Project Title
            </Form.Label>
            <Col sm={12} lg={true}>
              <Form.Control
                type="text"
                name="project_title"
                placeholder="Project Title"
                isInvalid={errors['project_title']}
                {...register('project_title')}
              />
              <Form.Control.Feedback type="invalid">
                {errors['project_title']?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="project_category">
            <Form.Label column sm={12} md={3} xxl={2}>
              Project Category
            </Form.Label>
            <Col sm={12} lg={true}>
              <Controller
                control={control}
                name="project_category"
                defaultValue={projectData.project_category}
                rules={{ required: 'This field is required.' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    options={project_categories_options}
                    placeholder="Select project category..."
                    defaultValue={{
                      value: value,
                      label: value
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    isSearchable
                    isClearable
                    // classNamePrefix="form-control"
                  />
                )}
              />
              {errors.project_category && (
                <div className="invalid-feedback d-block">
                  {errors?.project_category?.message}
                </div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="project_abstract">
            <Form.Label column sm={12} md={3} xxl={2}>
              Project Abstract
            </Form.Label>
            <Col sm={12} lg={true}>
              <Form.Control
                as="textarea"
                rows={5}
                name="project_abstract"
                placeholder={
                  team_category === 'Senior Division'
                    ? 'Project Abstract (required)'
                    : 'Project Abstract (optional)'
                }
                isInvalid={errors.project_abstract}
                {...register('project_abstract', {
                  required:
                    team_category === 'Senior Division'
                      ? 'The project abstract is required - please copy and paste the project abstract into the box above.'
                      : false
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.project_abstract?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <hr />
          <Col sm="12" className="mb-3">
            <h5 className="fs-1">
              Does your project include the use of one or more of the following
              (check all that apply):
            </h5>
            <div className="text-base">
              Prior approval of an SRC, RB, IACUC, or IBC is{' '}
              <strong>required</strong>
            </div>
          </Col>
          <Col sm="12">
            <Form.Check
              type="checkbox"
              name="restricted_humans"
              id="restricted_humans"
              label="Humans (including student designed inventions/prototypes)"
              {...register('restricted_humans')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_animals"
              id="restricted_animals"
              label="Vertebrate Animals"
              {...register('restricted_animals')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_biohazard"
              id="restricted_biohazard"
              label="Potentially Hazardous Biological Agents"
              {...register('restricted_biohazard')}
            />
            <Form.Check
              type="checkbox"
              name="restricted_hazard"
              id="restricted_hazard"
              label="Hazardous Chemicals, Activities, and Devices"
              {...register('restricted_hazard')}
            />
          </Col>

          <hr />
          <Col sm="12">
            <h5 className="fs-1">Upload Documents:</h5>
            <div className="text-base mb-3">
              Please attach all documents and forms here.
            </div>
            <div>
              <div {...getRootProps({ className: 'dropzone-area py-4' })}>
                <input {...getInputProps()} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">Drop your forms here</p>
                </Flex>
              </div>
            </div>
            <div>
              <div className="mt-3">
                {files.map(file => (
                  <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={file.path}
                  >
                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="ms-3 flex-1"
                    >
                      <div>
                        <h6>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{getSize(file.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                      <Button
                        variant="link"
                        className="text-danger fs-0"
                        onClick={() => removeFileHandler(file.path)}
                      >
                        Remove file
                      </Button>
                    </Flex>
                  </Flex>
                ))}
              </div>
            </div>
          </Col>
        </Card.Body>
      </Form>
    </>
  );
};

TeamProjectEdit.propTypes = {
  projectData: PropTypes.object,
  setProjectData: PropTypes.func,
  team_category: PropTypes.string,
  setEditMode: PropTypes.func,
  saveRef: PropTypes.object,
  setSubmittingStatus: PropTypes.func,
  isFormValid: PropTypes.bool
};

export default TeamProjectEdit;
